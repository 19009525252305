$search-form--height: 30px;

.b-search {
	&.b-search--compact {
		position: relative;
		@include rem(height, $search-form--height);

		.b-search__container {
			display: flex;
		}

		.b-search__label {
			position: absolute;
			color: $search--label-color;
			top: 0;
			@include rem(line-height, $search-form--height);
			@include rem(left, 5px);
			cursor: text;
			z-index: 1;
		}

		.b-search__label--outside {
			position: relative;
			height: 100%;
			left: 0;
			@include rem(width, 50px);
		}

		.b-search__wrapper-input {
			position: relative;
			@include rem(width, 150px);
			transition: width 0.3s ease;
		}


		.b-search__input-container {
			@include rem(margin-right, 1px);
		}

		.b-search__input {
			width: 100%;
		}

		.b-search__button {
			@include rem(height, $search-form--height);
			@include rem(width, 70px);
			color: $search--btn-icon;

			&:hover,
			&:focus,
			&.is-expand {
				color: $search--btn-color-hover;
			}

			&:active {
				color: $search--btn-color-active;
			}
		}

		&.is-expand {
			.b-search__wrapper-input {
				width: 400px;
			}
		}
	}
}
