.b-nav--top.b-nav--horizontal-wide {
	.b-nav__list--lvl1 {
		display: flex;
	}

	.b-nav__list--lvl2 {
		display: block;
		position: relative;
		width: 33.333%;
		height: 100%;
		background-color: transparent;
		z-index: 20;
	}

	.b-nav__list--lvl3 {
		display: none;
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		min-height: 100%;
		background-color: $menu--list-lvl3-bg;
	}

	.b-nav__list--lvl4 {
		display: none;
		position: absolute;
		left: 100%;
		top: 0;
		width: 100%;
		min-height: 100%;
		background-color: $menu--list-lvl4-bg;
	}

	.b-nav__sub-space {
		display: none;
		position: absolute;
		height: auto;
		width: 99.999%;
		left: 0;
		right: 0;
		overflow: hidden;
		background-color: $menu--sub-space-bg;
		z-index: 12;
	}

	.b-nav__item {
		display: block;
	}

	.b-nav__link {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
		transition: color 0.2s ease, background-color 0.2s ease;
		text-decoration: none;

		span {
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			@include rem(line-height, 15px);
		}

		&.is-expand,
		&:hover {
			color: $menu--link-color-expand-hover;
			background-color: $menu--link-bg-expand-hover;
		}

		&.current {
			color: $menu--link-color-current;
			background-color: $menu--link-bg-current;
		}
	}

	.b-nav__link--lvl1 {
		@include rem(padding, 16px);
		display: flex;
		align-items: center;
	}

	.b-nav__link--lvl2 {
		@include rem(padding, 16px 30px 16px 15px);
	}

	.b-nav__link--lvl3 {
		@include rem(padding, 16px 30px 16px 15px);
	}

	.b-nav__sub-space .has-submenu {
		> .b-nav__link {
			@include webfont-icon($webfont-icon--arrow-right, 'after');

			&:after {
				position: absolute;
				top: 0;
				@include rem(right, 15px);
				bottom: 0;
				margin: auto;
				@include font-size(10px);
				height: 10px;
			}
		}
	}
}
