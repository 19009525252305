.b-search-result {
	&__item {
		padding-top: $grid-gutter-width + 2px;
		@include rem(border-top-width, 1px);
		border-top-style: solid;
		border-color: $color-silver;

		&:last-child {
			border-bottom: 1px solid $color-silver;
		}
	}

	&__title {
		margin-bottom: 17px
	}

	&__text {
		color: $color-black-russian;
		margin-bottom: 28px;
		& .highlight {
			font-weight: 300;
			background-color: $color-tangerine-yellow;
		}
	}

	&__link {
		color: inherit;
		text-decoration: inherit;
		font-size: 26px;
		line-height: 27px;
		@include font(600, 'FiraSansExtraCondensed');
		transition: all 0.2ms;

		&:hover {
			text-decoration: underline;
			font-size: 26px;
			line-height: 27px;
			@include font(600, 'FiraSansExtraCondensed');
			color: $color-zambezi;
		}

		&:active,
		&:focus {
			text-decoration: underline;
			font-size: 26px;
			line-height: 27px;
			@include font(600, 'FiraSansExtraCondensed');
		}
	}
	
	&__loading-trigger {
		width: 100%;
		
		&.is-finidhed {
			display: none;
		}
		
		&.is-loading {
			height: 40px;
		}
	}
}
