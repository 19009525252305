$useful-links--btn-bg: $color-ironside-gray;
$useful-links--btn-color: $color-white;
$useful-links--bg: $color-eallery;
$useful-links--link-color: $color-black;
$useful-links--link-color-hover: $color-tapa-gray;

@if ($contrast) {
	$useful-links--btn-bg: $color-light-gray;
	$useful-links--btn-color: $base-text-color;
	$useful-links--bg: $color-light-gray;
	$useful-links--link-color: $color-ironside-gray;
	$useful-links--link-color-hover: $color-mine-shaft;
}

.b-useful-links {
	@include rem(margin-top, $grid-gutter-width);
	@include rem(padding, $grid-gutter-width);

	&__link {
		@extend %disable-visited;
		display: block;
		position: relative;
		@include font-size(13px);
		@include rem(padding-top, 7px);
		@include rem(padding-bottom, 7px);
		text-decoration: none;
		color: $useful-links--link-color;

		&:hover {
			color: $useful-links--link-color-hover;
			text-decoration: underline;
		}
	}
}
