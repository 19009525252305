$bc-link-color: $link-default-color;
$bc-link-color-hover: $link-hover-color;
$bc-link-color-active: $link-active-color;
$bc-link-color-visited: $bc-link-color;

$bc-home-icon-color: #444444;
$bc-home-icon-color-hover: $link-hover-color;
$bc-home-icon-color-active: $link-active-color;

$bc-item-color: black;
$bc-dash-color: $color-ironside-gray;

$bc-sub-bg: #cdcdcd;
$bc-sub-link-color: $link-default-color;
$bc-sub-link-color-hover: $link-hover-color;

@if ($contrast) {
	$bc-link-color: $link-default-color;
	$bc-link-color-hover: $link-hover-color;
	$bc-link-color-active: $link-active-color;
	$bc-link-color-visited: $bc-link-color;

	$bc-home-icon-color: #444444;
	$bc-home-icon-color-hover: $link-hover-color;
	$bc-home-icon-color-active: $link-active-color;

	$bc-item-color: black;
	$bc-dash-color: $color-ironside-gray;

	$bc-sub-bg: #cdcdcd;
	$bc-sub-link-color: $link-default-color;
	$bc-sub-link-color-hover: $link-hover-color;
}

.breadcrumbs {
	@extend .no-markers;
	margin-top: 0;
	@include font(300, 'Roboto');
	font-size: 13px;
	line-height: 17px;

	ul.breadcrumbs-sub {
		display: none;
		position: absolute;
		white-space: nowrap;
		@include rem(padding, 2px 5px);
		color: $bc-sub-link-color;
		background-color: $bc-sub-bg;
		margin-top: 0;
		z-index: 11;

		li.breadcrumbs-sub__item {
			margin-top: 0;
			padding-left: 0;

			& + .breadcrumbs-sub__item {
				@include rem(margin-top, 4px);
			}
		}

		&__link {
			color: $bc-sub-link-color;

			&:visited {
				color: $bc-sub-link-color;

				&:hover {
					color: $bc-sub-link-color-hover;
				}
			}

			&:hover {
				color: $bc-sub-link-color-hover;
				text-decoration: none;
			}
		}
	}

	ul.breadcrumbs__list {
		margin-top: 0;
	}

	&__item {
		position: relative;
		display: inline-block;
		color: #302884;
		vertical-align: middle;
		padding-right: 14px;

		& + .breadcrumbs__item {

			&:after {
				content: '';
				position: absolute;
				top: 4px;
				width: 1px;
				height: 13px;
				background-color: #302884;
				right: 7px;
			}

			&:last-child {
				&:after {
					content: none;
				}
			}
		}

		&:hover {
			.breadcrumbs-sub {
				display: block;
			}
		}
	}
	.breadcrumbs {
		&__link {
			color: #302884;
			text-decoration: underline;
			height: 17px;
			line-height: 17px;
			padding: 0 2px;
			@include font(300, 'Roboto');
	
			&:visited {
				color: #302884;
			}
	
			&:hover,
			&:focus {
				color: #302884;
				background: #FFF5CC;
	
				@include font(300, 'Roboto');
			}
	
			&:active {
				color: #302884;
				background: #FFD733;
	
				@include font(300, 'Roboto');
			}
		}
	
		&__link--home {
			position: relative;
			display: block;
			color: #302884;
			font-size: 0;
			@include webfont-icon($webfont-icon--i-home);
			text-decoration: none;
			width: 20px;
			height: 20px;
			padding: 0;
			margin: 0;
			text-align: center;
			line-height: 20px;
	
			&:before {
				@include font-size(15px);
				width: 20px;
				height: 20px;
				text-align: center;
				line-height: 20px;
				display: block;
			}
	
			&:hover,
			&:focus {
				color: #302884;
				background: #FFF5CC;
			}
	
			&:active {
				color: #302884;
				background: #FFD733;
			}
		}
	}
	
}
