$copyright_base--color: $color-zambezi;
$copyright_base--link-color: $color-zambezi;
$copyright_base--link-color-hover: $color-jacksons-purple;
$copyright_base--link-color-active: $color-tangerine-yellow;


.b-copyright {
	color: $copyright_base--color;
	font-size: 16px;
	line-height: 21px;
	@include font(300, "Roboto");

	&__name--link {
		color: $copyright_base--link-color;
		text-decoration: none;
		@include font(300, "Roboto");
		text-decoration: underline;

		&:visited {
			// color: #F5F5F5;//$copyright_base--link-color;
			color: $copyright_base--link-color;
			@include font(300, "Roboto");
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $copyright_base--link-color-hover;
			text-decoration: underline;
			@include font(300, "Roboto");
		}

		&:active {
			color: $copyright_base--link-color-active;
			text-decoration: underline;
			@include font(300, "Roboto");
		}
	}

	.body--main-page &__name--link {
		color: #F5F5F5;

		&:visited {
			color: #F5F5F5;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: #FFCD00;
		}

		&:active {
			color: #FFCD00;
		}
	}

	@include breakpoint(sm) {
		font-size: 13px;
		line-height: 17px;
	}
}
