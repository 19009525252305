//text
$base-text-color: $color-black;

//errors
$error-text-color: #c7191a;
$error-form-bg: #fea8a6;

//links
$link-default-color: $base-text-color;
$link-hover-color: $color-black;
$link-active-color: $color-carrot-orange;
$link-visited-color: $color-taupe-gray;

//buttons
$btn--bg: $color-eallery;
$btn--bg-hover: #656565;
$btn--bg-active: #343434;
$btn--color: $color-mine-shaft;
$btn--color-hover: $color-white;
$btn--color-active: $color-white;


// header
$top-line-bg: $color-grape;