$download-center--row-bg-color: transparent;
$download-center--row-border-bottom-color: $color-wan-white;
$download-center--bg-accordion-article: transparent;
$download-center--download-btn-bg: #656565;
$download-center--download-btn-disabled-bg: #738d97;
$download-center--download-btn-color: #fff;
$download-center--section-title-border-color: #7d7d7d;
$download-center--section-title-color: #343434;
$download-center--section-col-border-color: #7d7d7d;
$download-center--accordionButton-color: $color-jacksons-purple;

$download-center--checkbox-color: $color-zambezi;
$download-center--checkbox-hover-color: $color-jacksons-purple;
$download-center--checkbox-active-color: $color-jacksons-purple;

.b-download-center {
	width: 100%;
	
	&__head {}
	
	&__body {
		margin-bottom: 10px;
	}
	
	&__footer {
		//@include rem(padding, 0 1rem 0 1rem);
	}
	
	&__line {
		
	}
	
	&__btn-group {
		
	}
	
	&__btn + &__btn {
		@include rem('margin-left', 10px);
	}
	
	.b-download-center-section {
		& + .b-download-center-section .b-download-center-section__title {
			border-top: 1px dotted $download-center--section-title-border-color;
		}
		
		&:first-child .b-download-center-section__title {
			border-top: 0;
		}
	}

	&--page {
		max-width: 944px;
		margin: 0 auto;
	}
}

.b-download-center-section {
	margin-top: 0;
	
	&__title {
		@include font-size(18px);
		border-bottom: 1px dotted $download-center--section-title-border-color;
		@include rem(padding, 18px 15px);
		color: $download-center--section-title-color;
	}
	
	&__cols {
		@include rem(padding, 0 15px);
	}
	
	&__col {
		@include rem(padding, 18px 15px);
		border-right: 1px dotted $download-center--section-col-border-color;
	}
	
	
	&__col:last-child {
		border-right: 0;
	}
	
	&__col-title {
		@include font-size(1rem);
		@include rem(margin-bottom, 15px);
	}
}

.b-download-center-line {
	line-height: 1.3;
	margin-top: 0;
	@include rem(padding, 12px);
	@include font-size(18px);
	background-color: $download-center--row-bg-color;
	border-bottom: 1px solid $download-center--row-border-bottom-color;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: flex-end;

	&__action {
		
	}
	
	&__content {
		font-size: 16px;
		@include font(400, Roboto);
	}
	
	&__action + &__content {
		@include rem(padding-left, 15px);
	}
	
	&__content + &__action {
		// @include rem(padding-left, 15px);
		@include rem(margin-right, 11px);
	}
	
	&__meta {
		
	}
	
	&--sub {
		// @include font-size(1rem);
		// @include rem(padding, 5px 0);
		// background: transparent;
		// border-bottom: none;

		.b-download-center__footer & {
			border-bottom: 0;
		}
	}
	
	&--accordion {
		padding: 0;
		margin: 0;
		border-bottom: 0;
		
		& > .b-download-center-line__action {
			width: 100%;
		}
		
		.b-accordion {
			text-align: left;
			margin-bottom: 0;
		}
		
		a.b-accordion__button {
			color: $download-center--accordionButton-color;
			line-height: 21px;
			@include font-size(16px);
			@include rem(padding, 15px);
			@include rem(padding-right, 50px);
			@include rem(padding-left, 45px);
			position: relative;
			@include font(400, Roboto);
		}
		
		a.b-accordion__button:hover,
		a.b-accordion__button:focus {
			@include rem(padding-left, 45px);
			color: $download-center--accordionButton-color;
		}
		
		a.b-accordion__button.is-expand,
		a.b-accordion__button:active {
			@include rem(padding-left, 41px);
			color: $download-center--accordionButton-color;
		}
		
		
		.b-accordion__button:before {
			color: $color-dim-gray;
			// left: auto;
			// @include rem(right, 2.4rem);
			// @include rem(width, 0.15rem);
			// @include rem(height, 1.3rem);
		}
		
		.b-accordion__button:after {
			right: auto;
			@include rem(left, 2.3rem);
			@include rem(width, 0.15rem);
			@include rem(height, 1.3rem);
		}
		
		.b-accordion__header {
			width: 100%;
			position: relative;
			
			.b-checkbox {
				@include rem(left, 12px);
				@include rem(margin-top, -10px);
				position: absolute;
				top: 50%;
			}

			.b-checkbox__label {
				border-color: $download-center--checkbox-color;

				&:hover {
					border-color: $download-center--checkbox-hover-color;

					&:before {
						color: $download-center--checkbox-hover-color;
					}
				}
			}

			.b-checkbox__input:checked + .b-checkbox__label {
				border-color: $download-center--checkbox-active-color;
				background: $download-center--checkbox-active-color;

				&:before {
					display: block;
					opacity: 1;
					color: $color-tangerine-yellow;
				}
			}
		}
		
		.b-accordion__article {
			@include rem(padding, 5px 0px 2px 16px);
			background: $download-center--bg-accordion-article;
		}
		
		.b-accordion__article--sections {
			padding: 0;
		}
		
		.article-close {
			display: none;
		}
	}
}


@if ($responsive) {
	@include breakpoint(xs) {
		.b-download-center {
			&__footer {
				padding: 0;
			}
		}
		
		.b-download-center-line {
			@include font-size(14px);
			
			&__content {
				@include rem(padding-right, 10px);
			}
		}
		
		.b-download-center-line--accordion {
			.b-accordion__button {
				@include font-size(14px);
			}
		}
		
		.b-download-center-section {
			&__col {
				border-right: none;
			}
			
			&__col-title {
				@include rem(margin-bottom, 10px);
			}
			
			&__title {
				@include font-size(14px);
			}
		}
	}
}
