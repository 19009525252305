$menu_horizontal-cols--bg-sub-space: #fff;
$menu_horizontal-cols--bg-list-lvl2: #fff;
$menu_horizontal-cols--bg-expand-hover: #fff;
$menu_horizontal-cols--font-expand-hover: #fff;

.b-nav--top.b-nav--horizontal-cols {
	position: static;
	.b-nav__list--lvl1 {
		display: flex;
		justify-content: space-between;
		height: 100%;
		// border-bottom: 1px solid #9D9D9C;
	}

	.b-nav__list--lvl2 {
		display: block;
		position: relative;
		height: auto;
		background-color: $menu_horizontal-cols--bg-list-lvl2;
		z-index: 20;
		@include rem(padding-right, 35px);
	}

	.b-nav__list--lvl3 {
		@include rem(margin-top, 15px);
	}

	.b-nav__sub-content-wrapper {
		padding: 20px 0 40px;
		max-width: 772px;
		margin: 0 auto;
	}

	.b-nav__item--lvl1 {
		.browser-opera-23 & {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 72px;
		}
	}

	.b-nav__sub-space {
		display: none;
		position: absolute;
		left: 0;
		width: 100%;
		background-color: $menu_horizontal-cols--bg-sub-space;
		border-bottom: 3px solid $color-grape;
		@include rem(padding, 0 30px);
		z-index: 12;
		top: calc(100% + 1px);

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			height: calc(100% + 1px);
			top: 0;
			width: 9999px;
			background-color: $menu_horizontal-cols--bg-sub-space;
		}

		&:before {
			right: 100%;
		}

		&:after {
			left: 100%;
		}
	}

	.b-nav__list--lvl2 {
		position: relative;

		.line {
			&::after {
				display: none;
			}
		}
	}

	.b-nav__item {
		display: block;

		&--lvl1 {
			&:last-child {
				display: none;
			}

			&:nth-child(10) {
				& .line {
					&::after {
						display: none;
					}
				}
			}
		}
	}

	.b-nav__item--lvl1 + .b-nav__item--lvl1 {
		.b-nav__link--lvl1 {
		}
	}

	.b-nav__item--lvl1 {
		text-align: center;

		&:last-child {
			.line {
				&::after {
					display: none;
				}
			}
		}
	
	}

	.line {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	// 	width: 1px;
	// 	height: 100%;
	// 	display: inline-block;
	// 	vertical-align: middle;

		&::after {
			content: '';
			position: absolute;
			top: 50%;//0;
			margin-top: -21px;
			right: 0;
			width: 1px;
			height: 34px;
			background-color: rgba(157, 157, 156, .9);
		}
	}

	.b-nav__item--lvl2 {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 12px;
			width: 1px;
			height: calc(100% + 10px);
			background-color: $color-star-dust;
			z-index: 1;
		}

		&:last-child {
			&::before {
				display: none;
			}
		}
		// 	position: absolute;
		// 	left: -2em;
		// 	top: -0.5em;
		// 	display: inline-block;
		// 	width: 1.875em;
		// 	height: 2.33em;
		// 	@include svg(1.875em, 2.33em, "/images/icons/icon_rzd-marker2.svg");
		// }

		&.has-submenu .b-nav__link--lvl2 {
			// &:after {
			// 	content: '';
			// 	display: inline-block;
			// 	position: absolute;
			// 	border-right: 1px solid $menu--link-color;
			// 	left: -16px;
			// 	width: 1px;
			// 	height: calc(100%);
			// 	top: 1.1em;
			// }
		}

		// &:first-child {
		// 	&:before {
		// 		top: 0;
		// 	}
		// }
	}

	.b-nav__item--lvl2 + .b-nav__item--lvl2 {
		padding-top: 16px;
	}

	.b-nav__item--lvl3 {
		text-align: left;
		position: relative;

		// &:before {
		// 	content: '';
		// 	display: inline-block;
		// 	position: absolute;
		// 	border-bottom: 1px solid $menu--link-color;
		// 	left: -1em;
		// 	width: 1em;
		// 	top: 0.6em;
		// }

		// &:after {
		// 	content: '';
		// 	display: inline-block;
		// 	position: absolute;
		// 	border-right: 1px solid $menu--link-color;
		// 	left: -1em;
		// 	width: 1px;
		// 	height: calc(100% + 1em);
		// 	top: -1em;
		// }

		&:not(:first-child) {
			margin-top: 1em;
		}

		&:last-child:after {
			height: calc(0.6em + 1em);
		}
	}

	.b-nav__link {
		width: 100%;
		height: 100%;
		color: $menu--link-color;
		@include font-size($menu--font-size);

		&:hover,
		&:focus {
			color: $menu_horizontal-cols--font-expand-hover;
		}

		&:active {
			color: $menu_horizontal-cols--font-expand-hover;
		}

		span {
			display: inline-block;
			width: 100%;
			vertical-align: middle;
		}
	}

	.b-nav__link--lvl1 {
		@include font(400);
		color: $menu--link-color;
		position: relative;
		display: inline-flex;
		align-items: center;
		text-align: center;
		text-decoration: none;
		width: auto;
		@include font(400, 'FiraSansCondensed');
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		text-transform: uppercase;
		
		color: rgba(68,67,69,0.9);
		border-bottom: 6px solid transparent;
		padding: 0 5px 7px;
		position: relative;
		// vertical-align: middle;
		
		&:before,
		&:after {
			display: block;
			position: absolute;
			bottom: -6px;
			left: 50%;
			margin-left: -5px;
			border-bottom: 8px solid #9D9D9C;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
		}
		&:after {
			bottom: -8px;
			border-bottom-color: #fff;
		}

		&:hover,
		&:focus {
			color: rgba(68,67,69,0.9);
			border-bottom-color: rgba(157,157,156,0.5);
		}

		&.current {
			color: #000;
			border-bottom-color: #FFCD00;
		}
		&:active,
		&.is-expand {
			color: #000;
			border-bottom-color: #FFCD00;

			&:before,
			&:after {
				content: "";
			}
		}

		span {
			// // position: relative;

			// &::after {
			// 	content: '';
			// 	position: absolute;
			// 	top: 0;
			// 	right: 0;
			// 	width: 1px;
			// 	height: 34px;
			// 	background-color: #9D9D9C;
			// }
		}
	}

	.b-nav__link--lvl2 {
		font-size: 14px;
		line-height: 16px;
		@include font(400, "Roboto");
		padding-left: 0;
		text-align: left;
		display: inline-block;
		position: relative;
		color: $color-zambezi;
		padding-left: 23px;

		&:hover,
		&:focus {
			color: $color-black;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: -7px;
			border: 1px solid $color-zambezi;
			border-radius: 100%;
			width: 16px;
			height: 16px;
			background-color: $color-white;
			z-index: 2;
		}

		&::after {
			content: '';
			position: absolute;
			top: 6px;
			left: -1px;
			background-color: $color-black;
			width: 4px;
			height: 4px;
			border-radius: 100%;
			opacity: 0;
			z-index: 2;
		}

		&:hover {
			color: $color-black;
			&::after {
				opacity: 1;
			}
		}

		&:active {

			&::after {
				opacity: 1;
			}

			&::before {
				background-color: $color-tangerine-yellow;
			}
		}

		&.current {
			color: $color-jacksons-purple
		}
	}

	.b-nav__link--lvl3 {
		@include font(400);
		@include font-size(16px);
		@include rem(padding-left, 10px);
		line-height: 1.2;
		display: inline-block;

		&.current {
			@include font(700);
		}
	}

	@media (max-width: 1440px) {
		.b-nav__link--lvl1 {
			font-size: 12px;
		}
	}
}
