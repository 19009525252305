.body--map-10-10-10 {
	.scheme-15--bg {
		height: 600px;
		width: 800px;
		background-image: url(/images/schemes/ru/sxem15-01.svg);
		display: block;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.show-xs {
		display: none;
	}

	@include breakpoint(sm) {
		.scheme-15--bg {
			height: 550px;//500px;
			width: 700px;
		}
	}

	@include breakpoint(xs) {
		.scheme-15--bg {
			display: none;
		}

		.show-xs {
			display: block;
		}

		.figure-buttons {
			text-align: center;

			a {
				height: 53px;
			}
		}
	}
}

// .body--scheme-40-60-10 {
// 	.scheme-15--bg {
// 		height: 600px;
// 		width: 800px;
// 		background-image: url(/images/schemes/ru/sxem84_85-01.svg);
// 		display: block;
// 		background-repeat: no-repeat;
// 		background-size: cover;
// 	}
// }
