$zoom--overlay-bg-color: #212121;
$zoom--box-bg-color: $color-white;

$zoom--btn-color: $color-ironside-gray;
$zoom--btn-color-hover: $color-black;
$zoom--btn-color-active: $color-carrot-orange;

$zoom--close-btn-color: $zoom--btn-color;
$zoom--close-btn-color-hover: $zoom--btn-color-hover;
$zoom--close-btn-color-active: $zoom--btn-color-active;

.button--zoom {
	display: inline-block;
	background-color: transparent;
	@include webfont-icon($webfont-icon--i-zoom);
	font-size: 0;
	color: $zoom--btn-color;
	width: 27px;
	height: 27px;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

	&:before {
		width: 27px;
		height: 27px;
		display: inline-block;
		background: transparent;
		line-height: 27px;
		font-size: 18px;
		color: $color-star-dust;
		margin-right: 5px;
		vertical-align: middle;

		transition: color 0.5s, background 0.5s;
	}

	&:hover,
	&:focus {
		&:before {
			background: $color-china-ivory;
			color: $color-zambezi;
		}
	}

	&:active {
		&:before {
			background: $color-sunglow;
			color: $color-zambezi;
		}
	}
}
#cboxClose {

	position: absolute;
	top: 25px;
	right: 25px;
	transform-origin: center;
	width: 38px; //26px;
	height: 38px; //26px;
	text-align: center;
	line-height: 0;
	background-color: transparent;
	will-change: transform;
	text-align: center;
	color: #9D9D9C;
	@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */
	background: transparent;
	@include webfont-icon($webfont-icon--i-close);

	&:before {
		width: 38px; //26px;
		height: 38px; //26px;
		line-height: 38px;
		font-size: 26px;
		position: absolute;
		left: 0;
		top: 0;
		text-indent: 0;
	}

	&:hover,
	&:focus {
		color: #302884;
		background: #FFEB99;
	}

	&:active {
		color: #302884;
		background: #FFCD00;
	}
}

#cboxOverlay {
	background: $zoom--overlay-bg-color;
	opacity: 0.6;
	filter: alpha(opacity = 60);
}

#cboxContent {
	margin-top: 0;
}

#cboxLoadedContent {
	border: 1px solid #9D9D9C;
	background: #fff;
    padding: 81px 37px 30px;

	figcaption {
		text-align: left;
		font-size: 18px;
	}

	.caption__units,
	.b-caption__units {
		font-size: 20px;
	}

	.graphic {
		text-align: center;
	}

	.b-table {
		padding-bottom: 50px;

		figcaption {
			padding-right: 70px;
		}

		.figure-buttons {
			position: absolute;
			right: 0;
			margin-top: 0;
			bottom: 0;
			margin-right: 0;
		}
	}
}

@include breakpoint(sm) {
	#cboxLoadedContent {
		.b-table .figure-buttons {
			display: none;
		}
	}
}
