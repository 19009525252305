.b-lang--single {
	.b-lang__item {
		display: block;
		color: $lang-selector--color;
		text-decoration: none;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		@include font(400, FiraSansCondensed);
		font-size: 12px;
		line-height: 25px;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		position: relative;
		width: 25px;
		height: 25px;

		@include webfont-icon($webfont-icon--i-lang);

		span {
			position: relative;
		}

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			color: $lang-selector--color;
			@include font-size(25px);
			// z-index: -1;
		}

		&:hover,
		&:focus {

			color: #FFEB99;

			&:before {
				color: $lang-selector--color-hover;
			}
		}

		&:active {
			color: #444345;
			@include webfont-icon($webfont-icon--i-lang-hover);
			&:before {
				color: $lang-selector--color-active;
			}
		}

		&--ru {
		}

		&--en {
		}
	}
}
