$tabs--button-bg: $color-white-smoke;
$tabs--button-bg-active: $color-tangerine-yellow;
$tabs--button-bg-hover: $color-whisper;
$tabs--button-text-color: $color-black;
$tabs--button-text-color-hover: $color-black;
$tabs--body-bg: $color-white;

.b-tabs {
	margin-bottom: 40px;
	
	&__button {
		@extend %disable-visited;
		@extend %collapse--top;
		display: flex;
		flex-grow: 1;
		flex-shrink: 1;
		justify-content: center;
		align-items: center;
		background-color: $tabs--button-bg;
		padding: 13px 5px;
		position: relative;
		text-decoration: none;
		color: $tabs--button-text-color;
		text-align: center;
		height: 100%;

		@include font(600, 'FiraSansExtraCondensed');
		font-size: 18px;
		line-height: 22px;
		
		transition: all .2s ease-in-out;

		&:visited {
			color: $tabs--button-text-color;
		}

		&:focus {
			color: $tabs--button-text-color;
			background-color: $tabs--button-bg;
			@include font(600, 'FiraSansExtraCondensed');

			&:visited {
				color: $tabs--button-text-color;
				background-color: $tabs--button-bg;
			}
		}

		&:hover {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-hover;
			@include font(600, 'FiraSansExtraCondensed');

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-hover;
			}
		}

		&.is-expand,
		&.is-expand:focus {
			color: $tabs--button-text-color-hover;
			background-color: $tabs--button-bg-active;
			@include font(600, 'FiraSansExtraCondensed');

			&:visited {
				color: $tabs--button-text-color-hover;
				background-color: $tabs--button-bg-active;

			}
		}
	}

	&__nav-item {
		border-left: 1px solid $color-white;
		flex: 1 0 auto;

		&:first-child {
			border-left: none;
		}
	}

	&__body {
		@extend %collapse--top;
		background-color: $tabs--body-bg;
		width: 100%;
	}

	&__article {
		@extend %collapse--top;
		position: absolute;
		left: -99999px;
		top: -99999px;
		padding: 18px 18px 2px 26px;
		@include font(300, 'Roboto');
		font-size: 16px;
		line-height: 21px;

		color: $color-black;

		&.is-expand {
			position: relative;
			left: 0;
			top: 0;
		}
	}
}
