$b-social-link-size: 30px;
$b-social-icon-size: 20px;
$b-social-icon: #5F5E5E;
$b-social-icon-hover: #302884;
$b-social-icon-active:#FFD733;

$b-social-link-bg: #5F5E5E;
$b-social-link-bg-hover: #302884;
$b-social-link-bg-active: #FFD733;


.b-social {
	.b-social__list {
		font-size: 0;
		@extend .user-select--no;
	}

	.b-social__item {
		display: inline-block;
		vertical-align: top;
		margin-left: 19px;
	}

	.b-social__link,
	.b-social__button {
		display: block;
		@include rem(width, $b-social-link-size);
		@include rem(height, $b-social-link-size);
		overflow: hidden;
		position: relative;
		color: $b-social-link-bg;
		transition: background-color 0.2s ease;
		text-decoration: none;

		&:hover {
			color: $b-social-link-bg-hover;
			&:before {
				color: $b-social-link-bg-hover;
			}
		}

		&.is-expand,
		&:active {
			color: $b-social-link-bg-active;
			&:before {
				color: $b-social-link-bg-active;
			}
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include font-size(20px);
			color: $b-social-icon;
		}
	}

	.b-social__list--lvl2 {
		position: absolute;
		white-space: nowrap;
		width: auto;
		display: none;
		bottom: 100%
	}

	.has-sublist {
		position: relative;
	}

	.b-social__item--lvl2 {
		display: block;
	}

	.b-social__link--lvl2 {
		background-color: red;
	}

	.b-social__link--twitter {
		@include webfont-icon($webfont-icon--twitter);
	}

	.b-social__link--facebook {
		@include webfont-icon($webfont-icon--i-social-fb);
	}

	.b-social__link--linkedin {
		@include webfont-icon($webfont-icon--linkedin);
	}

	.b-social__link--vk {
		@include webfont-icon($webfont-icon--i-vk);
		// @include font-size(16px);
	}

	.b-social__link--youtube {
		@include webfont-icon($webfont-icon--i-social-youtube);
	}

	.b-social__link--instagram {
		@include webfont-icon($webfont-icon--i-social-insta);
	}

	.b-social__link--foursquare {
		@include webfont-icon($webfont-icon--foursquare);
	}

	@include breakpoint(sm) {
		.b-social__item {
			margin-left: 5px;
		}
	}
}

