.b-search {
	&.b-search--result {
		position: relative;
		width: 100%;
		@include rem(height, 35px);
		display: flex;
		
		
		.b-search__label {
			position: absolute;
			color: $search--label-color;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(left, 5px);
			height: 100%;
			cursor: text;
			@include rem(line-height, 30px);
		}

		.b-search__input {
			width: 100%;
			height: 100%;

			.title--search & {
				line-height: 21px;
				border: 1px solid $color-silver;
				padding-left: 15px;
			}
		}

		.b-search__wrapper-input {
			display: block;
			height: 100%;
			width: 100%;
		}
		
		.b-search__button {
			display: block;
			height: 100%;

			.title--search & {
				width: 92px;
				padding: 0 16px;
			}
		}
	}
}
