$title--text-color: $color-black;
$title--link-color: $link-default-color;
$title--link-color--hover: $link-hover-color;
$title--link-color--active: $link-hover-color;
$title--link-color--visited: $link-default-color;


.b-report-title {
	@include font(600, 'FiraSansCondensed');
	font-size: 17px;
	line-height: 20px;
	text-transform: uppercase;
	color: #FFFFFF;
	text-decoration: none;

	span {
		color: #FFCD00;
	}

	&__link {
		@include font(600, 'FiraSansCondensed');
		font-size: 17px;
		line-height: 20px;
		text-transform: uppercase;
		color: #FFFFFF;
		text-decoration: none;
		span {
			color: #FFCD00;
		}

		&:visited,
		&:hover,
		&:focus,
		&:active {

			.b-report-title & {
				color: #FFFFFF;
			}
			@include font(600, 'FiraSansCondensed');
			span {
				color: #FFCD00;
			}
		}
	}

	@include breakpoint(sm) {
		font-size: 12px;
		line-height: 15px;
		&__link {
			font-size: 12px;
			line-height: 15px;
		}
	}
}
