.figure-buttons {
	position: relative;
	text-align: right;
	margin-top: 20px;
    // margin-right: -8px;

	.button + .button {
		margin-left: 12px;
	}

	.button {
		padding: 0;
		text-decoration: none;
		vertical-align: top;
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.button--excel + .button {
			margin-left: 0;
		}
	}

	@include breakpoint(sm) {
		.figure-buttons {
			margin-top: 10px;
			text-align: left;
		}

		.figure-buttons .button {
			text-align: left;
			padding: 10px 15px;
			background: $color-drover;
			position: relative;
			width: 100%;
			max-width: 162px;
			text-decoration: none;
			color: $color-jacksons-purple;
			font-size: 18px;
			line-height: 18px;
			@include font(600, "Roboto");
			text-transform: uppercase;
			height: 42px;

			&:before {
				position: absolute;
				font-size: 23px;
				right: 7px;
				top: 50%;
				transform: translateY(-50%);
				color: $color-zambezi;
			}

			& + .button {
				margin-top: 10px;
				margin-left: 0;
			}

			&:hover,
			&:active {
				background: $color-tangerine-yellow;
			}
		}
	}
}
