blockquote {
    position: relative;
    padding: 12px 0 30px 23px;
    border-bottom: none;
    margin-bottom: 40px;

	.header {
		border-bottom: 1px solid $color-black-magic;

		@include font(400, 'Roboto'); // ЗАМЕНИТЬ НА 500
		font-size: 20px;
		line-height: 24px;
		color: #005698;
		position: relative;
		padding-left: 30px;
		margin-left: 40px;
		padding-bottom: 4px;
		margin-right: -25px;
		margin-bottom: 41px;

		&:before {
			content: "";
			border: 1px solid $color-black-magic;
			border-radius: 100%;
			width: 52px;
			height: 52px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translate(-40px, 50%);
		}

		&:after {
			content: "";
			background: $color-black-magic;
			border-radius: 100%;
			width: 10px;
			height: 10px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translate(0, 50%);
		}
	}

	.text {
		position: relative;
		@include font(300, 'Roboto');
		font-size: 20px;
		line-height: 27px;
		margin-bottom: 17px;
		text-transform: uppercase;
		color: #005698;

		@include webfont-icon($webfont-icon--i-quote);

		&:before {
			position: absolute;
			font-size: 29px;
			top: -57px;
			left: 12px;
			color: $color-black-magic;
		}
	}

	.person {
		@include font(400, 'Roboto');
		font-size: 16px;
		line-height: 19px;
		display: flex;
		align-items: flex-end;
		flex-direction: column;

		color: $color-jacksons-purple;

		&__name {
			display: block;
			font-size: 20px;
			line-height: 23px;
			color: #005698;
			text-transform: uppercase;
		}

		&__more {
			text-transform: uppercase;
			font-size: 16px;
			line-height: 19px;
			color: $color-star-dust;
			margin-bottom: 15px;

			// &::before {
			// 	content: '';
			// 	position: absolute;
			// 	height: 1px;
			// 	background-color: $color-tangerine-yellow;
			// 	left: 0;
			// 	width: calc(100% - 105px);
			// 	bottom: 56px;
			// 	display: block;
			// 	z-index: 10;
			// }

			// @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			// 	&::before {
			// 		bottom: 58px;
			// 	}
			// }
		}
		
		&--image {
			&::before {
				content: '';
				position: absolute;
				height: 1px;
				background-color: $color-tangerine-yellow;
				left: 0;
				width: calc(100% - 105px);
				bottom: 56px;
				display: block;
				z-index: 10;
			}
		}

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			&--image {
				&:before {
					bottom: 58px;
				}
			}
		}

		@supports (-ms-ime-align:auto) {
			&--image {
				&:before {
					bottom: 58px;
				}
			}
		}

		

		&__position {
			font-size: 16px;
			line-height: 19px;
			display: block;
			color: $color-star-dust;
			@include font(400, 'FiraSansExtraCondensed');
			max-width: 350px;
			text-transform: uppercase;
			text-align: right;
		}

		&__company {
			display: block;
		}
	}

	@include breakpoint(sm) {
	 	.header {
			margin-left: 0;
		}

		.text {
			font-size: 14px;
			line-height: 17px;
		}

		.person {

			&__name {
				font-size: 18px;
				line-height: 20px;
			}
		}
	}
}

.quote--photo {
	// display: flex;
	padding: 0;

	.wrapper-top {
		display: flex;
		justify-content: space-between;
		position: relative;
		align-items: center;
		margin-bottom: 20px;
	}

	.person {
		align-items: flex-start;

		&__position {
			text-align: left;
			max-width: 588px;
		}

		&__name {
			margin-bottom: 2px;
		}
	}

	.text {
		&::before {
			display: none;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: -23px;
			left: 0;
			width: 100%;
			max-width: 543px;
			height: 1px;
			background-color: $color-tangerine-yellow;
		}
	}

	.image-wrapper {
		width: 119px;
		height: 119px;
		overflow: hidden;
		border: 2px solid $color-tangerine-yellow;
		border-radius: 100%;
		flex: 1 0 auto;
		max-width: 119px;
		// position: relative;

		&::after {
			content: '';
			position: absolute;
			right: 103px;
			width: 8px;
			height: 8px;
			background-color: $color-tangerine-yellow;
			border-radius: 100%;
			bottom: 53px;
			display: block;
		}
	}


	.image-blockquote {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.quote__photo {
		flex-shrink: 0;
		width: 30%;

		img {
			display: block;
		}
	}

	.quote__content {
		position: relative;
		padding: 30px 16px 30px 80px;
	}
}

@include breakpoint(sm) {
	blockquote {
		padding: 24px 16px 24px 40px;

		.text {
			&:before {
				left: -28px;
			}
		}

		.person {
			&__more {
				&:before {
					display: none;
				}
			}
		}
	}

	.quote--photo {
		display: block;
		padding: 24px 16px 24px 0;

		.quote__photo {
			width: 95%;
			margin-bottom: 25px;
		}

		.quote__content {
			position: relative;
			padding: 0;
		}

		.wrapper-top {
			flex-direction:  column;
		}

		.image-wrapper {
			&::before,
			&::after {
				display: none;
			}
		}
	}
}
