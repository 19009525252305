button,
input,
optgroup,
select,
textarea {
	color: inherit; // 1. Correct color not being inherited. Known issue: affects color of disabled elements.
	font: inherit; // 2. Correct font properties not being inherited.
	margin: 0; // 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
}

// Address `overflow` set to `hidden` in IE 8/9/10/11.
button {
	overflow: visible;
}

// Address inconsistent `text-transform` inheritance for `button` and `select`.
// All other form control elements do not inherit `text-transform` values.
// Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
// Correct `select` style inheritance in Firefox.
button,
select {
	text-transform: none;
}

// 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio` and `video` controls.
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button; // 2. Correct inability to style clickable `input` types in iOS.
	cursor: pointer; // 3. Improve usability and consistency of cursor style between image-type `input` and others.
}

// Re-set default cursor for disabled elements.
button[disabled],
html input[disabled] {
	cursor: default;
}

// Remove inner padding and border in Firefox 4+.
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

// Address Firefox 4+ setting `line-height` on `input` using `!important` in
// the UA stylesheet.
input {
	line-height: normal;

	&:focus {
		outline: 0;
	}
}

// It's recommended that you don't attempt to style these elements.
// Firefox's implementation doesn't respect box-sizing, padding, or width.
input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box; // 1. Address box sizing set to `content-box` in IE 8/9/10.
	padding: 0; // 2. Remove excess padding in IE 8/9/10.
}

// Fix the cursor style for Chrome's increment/decrement buttons. For certain
// `font-size` values of the `input`, it causes the cursor style of the
// decrement button to change from `default` to `text`.
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

input[type="email"],
input[type="search"] {
	-webkit-appearance: textfield; // 1. Address `appearance` set to `searchfield` in Safari and Chrome.
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box; // 2. Address `box-sizing` set to `border-box` in Safari and Chrome (include `-moz` to future-proof).
	box-sizing: border-box;
}

// Remove inner padding and search cancel button in Safari and Chrome on OS X.
// Safari (but not Chrome) clips the cancel button when the search input has
// padding (and `textfield` appearance).
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

// Define consistent border, margin, and padding.
fieldset {
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	border: 0; // 1. Correct `color` not being inherited in IE 8/9/10/11.
	padding: 0; // 2. Remove padding so people aren't caught out if they zero out fieldsets.
}

// Remove default vertical scrollbar in IE 8/9/10/11.
textarea {
	overflow: auto;
	outline: none;
}

// Don't inherit the `font-weight` (applied by a rule above).
// NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
optgroup {
	font-weight: bold;
}

//Reset autofill form style for webkit
input:-webkit-autofill,
select:-webkit-autofill,
textarea:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0 $input-height $color-white !important;
	-webkit-text-fill-color: $base-text-color !important;
	color: $base-text-color !important;
	border: 1px solid $color-dark-gray;
}

input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="week"] {
	height: $input-height;
	border: 1px solid transparent;
	border-bottom: 1px solid #9d9d9c;
	padding: $input-padding;

	font-family: Roboto;
	font-weight: 300;
	font-size: 16px;
	color: #000;
}

textarea {
	border: 1px solid #9d9d9c;
	padding: $textarea-padding;
	font-family: Roboto;
	font-weight: 300;
	font-size: 16px;
	color: #000;
}

//Невалидное текстовое поле
input.is-error{
	border-bottom-color: #9d9d9c;
}
textarea.is-error {
	border-color: #9d9d9c;
}
