.graphic {
	margin-bottom: 20px;

	&__img {
		@extend %collapse--top;
		width: 100%;
	}

	figcaption {
		border-bottom: 1px solid $color-black-magic;//$color-jacksons-purple;

		@include font(600, 'FiraSansExtraCondensed'); // ЗАМЕНИТЬ НА 500
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: $color-black-magic;//$color-jacksons-purple;
		position: relative;
		padding-left: 59px;
		margin-left: 13px;
		padding-bottom: 7px;
		margin-bottom: 50px;
		font-weight: 500;

		&:before {
			content: "";
			border: 1px solid $color-black-magic;//$color-jacksons-purple;
			border-radius: 100%;
			width: 26px;
			height: 26px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translate(-50%, 50%);
		}

		&:after {
			content: "";
			background:  $color-black-magic;//$color-jacksons-purple;
			border-radius: 100%;
			width: 14px;
			height: 14px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translate(-50%, 50%);
		}

		.caption__units {
			font-weight: normal;
		}
	}

	.figure-buttons {
		margin-top: 15px;
	}

	@include breakpoint(sm) {
		figcaption {
			padding-left: 12px;
		}
	}
}
.graphic__img {
	width: 100%;
}

.graphic__tools {
	@extend %clearfix;
	@extend %collapse--top;
	position: relative;
	border-top: 1px solid;

	.button + .button {
		margin-right: 1px;
	}
}
