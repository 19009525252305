.b-sitemap {

	&.container-fluid {
		padding: 0;
	}

	&__list,
	&__item,
	&__link {
		margin-top: 0;
	}

	&__item {
		margin-bottom: 11px !important;

		&::before {
			background-color: $color-tangerine-yellow !important;
		}
	}

	&__link {
		display: inline;
		text-decoration: none;
		@include rem(margin-bottom, 10px);
		@include font(300);
		@include font-size(16px);
		font-weight: 300;
		font-size: 16px;
		line-height: 21px;

		&:hover {
			font-weight: 300;
			text-decoration: underline;
		}

		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			font-weight: 300;
		}

		&:active,
		&:visited:active {
			font-weight: 300;
		}

		&:active,
		&:hover {
			font-weight: 300;
		}
	}

	&__link--lvl1 {
		@include font(400, "FiraSansCondensed");
		@include font-size(18px);
		font-weight: 400;
		font-size: 18px;
		line-height: 22px;
		text-transform: uppercase;
		color: $colorjacksons-purple-09;

		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			color: $color-zambezi;
			@include font(400, 'FiraSansCondensed');
		}

		&:active,
		&:visited:active {
			color: $color-tangerine-yellow;
			@include font(400, 'FiraSansCondensed');
		}

		&:active,
		&:hover {
			outline: 0;
			@include font(400, 'FiraSansCondensed');
		}
	}
	

	&__link--lvl3 {
		@include font-size(14px);
		font-size: 14px;
		line-height: 18px;
	}

	&__link--lvl4 {
		@include font-size(13px);
	}
	
	&__list--lvl1 {
		& > .b-sitemap__item {
			@include rem(margin-bottom, 25px);
		}
	}

	&__list--lvl1 > &__item {
		padding-left: 0!important;

		&::before {
			content: none;
		}
	}

	&__list--lvl2 > &__item {
		padding-left: 17px !important;

		&::before {
			left: 2px !important;
		}
	}

	&__list--lvl3 > &__item {
		padding-left: 25px !important;

		&::before {
			left: 8px !important;
		}
	}
}
