$extendable-input-width: 500px;
$extendable-input-border-color: #cccccc;

.b-search {
	&.b-search--extendable {
		position: relative;
		width: auto;

		.b-search__wrapper-input {
			@extend %d-none;
			width: 466px;
			height: 33px;
			z-index: 10;
			position: absolute;
			right: -5px;
			top: -4px;
			background: #444345;
		}

		.b-search__label {
			position: absolute;
			margin: auto;
			top: 0;
			bottom: 0;
			@include rem(height, 33px);
			@include rem(left, 5px);
			cursor: text;

			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 33px;

			color: #444345;
		}
		.b-search__input-container {
			padding-right: 36px;
		}
		.b-search__input {
			width: 100%;
			height: 33px;
			margin-top: 0;
			border: none;
			background: #FFF5CC;
			font-family: Roboto;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 16px;

			color: #444345;
		}

		.b-search__button {
			display: block;
			padding: 0;
			background-color: transparent;
			color: $search--btn-icon;
			@include webfont-icon($webfont-icon--i-search);
			@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

			&:before {
				display: block;
				@include font-size(25px);
				color: #fff;
			}

			&:hover,
			&:focus {
				&:before {
					color: #FFEB99;
				}
			}

			&:active,
			&.is-expand {
				&:before {
					color: #FFCD00;
				}
			}
		}
		.b-search__button-close {
			@include webfont-icon($webfont-icon--i-search-close);
			@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

			&:before {
				display: block;
				@include font-size(25px);
				color: #FFCD00;
			}

			&:hover,
			&:focus {
				&:before {
					color: #FFCD00;
				}
			}

			&:active,
			&.is-expand {
				&:before {
					color: #FFCD00;
				}
			}
		}

		.b-search__result {
			@include rem(width, $extendable-input-width);
		}

		@include breakpoint(sm) {
			position: static;
			.b-search__wrapper-input {
				width: calc(100% - 10px);
				right: 5px;
				top: 3px;
			}
			.b-search__input-container {
				padding-right: 48px;
			}
		}
	}
}
