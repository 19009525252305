$btn--padding-vertical: 0.667em; // отступы по сверху и снизу кнопок
$btn--padding-horizontal: 0.567em; // отступы по краям кнопок

button {
	border: 0;

	&:focus {
		outline: 0;
	}
}

%button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	color: $btn--color;
	border: 0;
	background-color: $btn--bg;
	cursor: pointer;
	transition: transform 0.2s ease, color 0.2s ease, background-color 0.2s ease;

	@include font(700, 'Roboto');
	font-size: 18px;
	line-height: 21px;

	&:hover,
	&:focus {
		color: $btn--color-hover;
	}

	&:focus {
		outline: 0;
	}

	&:active {
		color: $btn--color-active;
		transform: translateY(1px);
	}
}

button[disabled="disabled"],
button:disabled {
	@extend .btn--disabled;
}

.btn {
	@extend %button;
	padding: $btn--padding-vertical $btn--padding-horizontal;
	text-decoration: none;

	text-align: center;

	color: $color-white;
	background: #C1C0C0;
	height: 40px;
	padding: 0 35px;

	&__text {
		position: relative;
		line-height: 1;
		z-index: 1;
	}

	&:hover,
	&:focus {
		background-color: #FFEB99;
		color: #302884;
	}

	&:active {
		background-color: #FFD733;
		color: #302884;
	}

	&:visited {
		background-color: #E2E1DE;
		color: #5F5E5E;
	}
}

input[type="submit"] {
	@extend %button;
}

.btn--disabled {
	background-color: #E2E1DE;
	color: #fff;
	// opacity: 0.3;
	cursor: default;

	&:hover {
		color: #fff;
		background-color: #E2E1DE;
	}
}

.btn .webfont-icon {
	margin: 0 0.4em;
}
