// .b-logo--expandable {
// 	overflow: hidden;
// 	width: 35px; //Ширина до наведения
// 	height: 40px;

// 	.b-logo__image {
// 		width: 130px; //Ширина после наведения, также нужно задать в config.yaml
// 	}
// }
.b-logo {
    &--ros {
        width: 112px;
        display: inline-block;
        margin-right: 48px;
    }
    &--main {
        width: 153px;
        display: inline-block;
    }
    &--ros & {
    }
    &--main & {
    }

    @media (max-width: 1000px) {
        &--ros {
            width: 70px;
            margin-right: 18px;
        }

        &--main {
            width: 100px;
        }
	}

    @include breakpoint(sm) { 

		&--ros {
            width: 30px;
            margin-right: 10px;
		}
		&--main {
			width: 44px;
        }
    }
}