.b-buttons-panel--in-column {
	position: relative;

	.b-buttons-panel__trigger {
		display: block;
		background-color: transparent;
		color: $buttons-panel--trigger-color;
		font-size: 0;
		padding: 0;
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			display: block;
			font-size: 25px;
		}

		&:hover,
		&:focus {
			color: $buttons-panel--trigger-color-hover;
			background-color: transparent;
		}

		&:active,
		&.is-expand {
			color: $buttons-panel--trigger-color-active;
			background-color: transparent;
		}

		&[disabled] {
			color: $buttons-panel--trigger-color;
		}
	}

	.b-buttons-panel__trigger--tools {
		@include webfont-icon($webfont-icon--i-tools);
		&:active,
		&.is-expand {
			@include webfont-icon($webfont-icon--i-tools-full);
			color: $buttons-panel--trigger-color-active;
		}
	}

	.b-buttons-panel__list {
		position: relative;
		top: 100%;
		right: 0;
		margin-top: 14px;
		z-index: 20;
		border: 1px solid #9D9D9C;
		width: 160px;

		&:before {
			content: "";
			display: inline-block;
			right: -1px;
			top: -17px;//-21px;
			position: absolute;
			border-top: 10px solid transparent;
			border-left: 10px solid transparent;
			border-right: 10px solid #9D9D9C;
			border-bottom: 10px solid #9D9D9C;
			z-index: 1;
		}
		&:after {
			content: "";
			display: block;
			right: 0px;
			top: -14px;
			position: absolute;
			border-top: 10px solid transparent;
			border-left: 10px solid transparent;
			border-right: 10px solid #fff;
			border-bottom: 10px solid #fff;
			z-index: 2;
		}
	}

	.b-buttons-panel__item {
		margin: 0;
		padding: 0;
		position: relative;
		z-index: 3;

		&:before {
			content: none;
		}
	}

	.b-buttons-panel__link {
		// white-space: nowrap;
		@include rem(padding, 5px 15px 5px 45px);
		border-bottom: 1px solid #9D9D9C;
		height: 40px;
		display: flex;
		align-items: center;
		@include font(300, 'Roboto');
		font-weight: 300;
		font-size: 13px;
		line-height: 15px;

		&:before {
			@include rem(left, 20px);
		}

		&--add,
		&--del {
			display: none;
			&.is-visible {
				display: flex;
			}
		}
	}
	.b-buttons-panel__item:last-child .b-buttons-panel__link {
		border-bottom: 0;
	}

	.b-buttons-panel__sublist--share,
	.b-buttons-panel__sublist--social {
		@extend %clearfix;
		@include rem(padding, 0 10px);
	}

	.b-buttons-panel__sublist-link--compare {
		text-align: center;
	}

	.b-buttons-panel__sublist:not([data-sublist-slide-type="bottom"]) {
		position: absolute;
		padding: 0;
		top: 0;

		@for $i from 1 through 15 {
			&[data-length="#{$i}"] {
				width: $buttons-panel--sublist-link-width * $i;
			}
		}

		&[data-sublist-slide-type="left"] {
			right: 100%;
		}

		&[data-sublist-slide-type="right"] {
			left: 100%;
		}
	}
}

.b-buttons-panel--in-column.b-buttons-panel--static {
	background-color: $buttons-panel--list-bg-color;
	@include rem(padding-top, $grid-gutter-width);
	@include rem(padding-bottom, $grid-gutter-width);

	.b-buttons-panel__title {
		display: block;
		@include rem(padding-left, $grid-gutter-width);
		@include rem(padding-bottom, $grid-gutter-width);
		@include font-size($buttons-panel--title-font-size);
		color: $buttons-panel--title-color;
	}

	.b-buttons-panel__link {
		white-space: normal;
	}
}

.b-buttons-panel--in-column.b-buttons-panel--compact {
	.b-buttons-panel__list {
		position: absolute;
		display: none;
		background-color: $buttons-panel--list-bg-color;
	}
}

// Панель с текстом в кнопке тригера
.b-buttons-panel--in-column.b-buttons-panel--button-with-text:not(.b-buttons-panel--download-pdf) {
	@include rem(max-width, 250px);

	.b-buttons-panel__trigger-controller {
		position: relative;
		width: 20px;
		height: 20px;
		margin-left: 14px;

		&:before,
		&:after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: 14px;
			background-color: currentColor;
			top: 0;
			bottom: 0;
			margin: auto;
			transition: transform .2s ease;
			border-radius: 5px;
		}

		&:before {
			right: 13px;
			transform: rotate(-44deg);
		}

		&:after {
			right: 4px;
			transform: rotate(44deg);
		}
	}

	.b-buttons-panel__trigger {
		display: flex;
		align-items: center;
		width: 100%;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		&.is-expand .b-buttons-panel__trigger-controller {
			&:before {
				transform: rotate(44deg);
			}

			&:after {
				transform: rotate(-44deg);
			}
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
	}

	.b-buttons-panel__list {
		width: 100%;
	}
}

// Панель скачать пдф
.b-buttons-panel--in-column.b-buttons-panel--download-pdf {
	@include rem(max-width, 160px);

	.b-buttons-panel__trigger {
		@include webfont-icon($webfont-icon--i-pdf);

		&.is-expand {
			@include webfont-icon($webfont-icon--i-pdf-full);
		}
	}

	.b-buttons-panel__trigger--with-text {
		display: flex;
		align-items: center;
		@include font-size($buttons-panel--trigger-text-font-size);

		&:before {
			margin-right: 9px;
		}

		.b-buttons-panel__trigger-controller {
			display: none;
		}
	}

	.b-buttons-panel__link {
		white-space: normal;
		@include rem(padding-left, 50px);
	}

	.b-buttons-panel__link--pdf-spreads {
		@include webfont-icon($webfont-icon--i-spreads);
	}

	.b-buttons-panel__link--pdf-pages {
		@include webfont-icon($webfont-icon--i-page);
	}
}

@include breakpoint(xs) {
	.b-buttons-panel--in-column {
		.b-buttons-panel__sublist--share {
			width: auto;
			height: auto;
		}

		.b-buttons-panel__sublist-link {
			float: none;
		}
	}
}
