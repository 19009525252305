// Цвет фона фиксированной шапки
$table--fixed-head-bg: $color-tangerine-yellow;
// Шапка
$table--header-color: $color-black;
$table--header-bg: $color-tangerine-yellow;
// Подзаголовок
$table--subhead-color: $color-navy-blue;
// Выделенная ячейка
$table--strong-cell-color: $base-text-color;
$table--strong-cell-bg: $color-celeste;
$table--strong-cell-border: $color-white;
// Строка итогов
$table--summary-bg: $color-celeste;
$table--summary-color: $base-text-color;
$table--summary-hover: $color-mountain-mist;
$table--summary-active-js: $table--summary-hover;
// Фон строки по наведению
$table-row-bg-hover: #F2F2F2;
$table-row-bg-strong-hover: #F2F2F2;
// Выделенная строка
$table--strong-row-color: $table--strong-cell-color;
$table--strong-row-bg: $table--strong-cell-bg;
$table--strong-row-bg-hover: $color-mountain-mist;
$table--strong-row-bg-js: $table--strong-row-bg-hover;
// Выделенная строка (при клике)
$table--selected-row-bg: #E4E4E4;
$table--selected-row-bg-strong: #E4E4E4;

table {
	border-collapse: collapse;
	border-spacing: 0;

	td,
	th {
		padding: 0;
	}

	.a_l {
		text-align: left !important;
	}

	.a_c {
		text-align: center !important;
	}

	.a_r {
		text-align: right !important;
	}

	.a_t {
		vertical-align: top !important;
	}

	.a_m {
		vertical-align: middle !important;
	}

	.a_b {
		vertical-align: bottom !important;
	}
}

// Стили для финансовой таблицы (стандартной)
.b-table {
	position: relative;

    margin-bottom: 30px;
	// figcaption {
	// 	@include font-size(16px);
	// 	font-weight: bold;
	// 	color: $base-text-color;
	// 	margin-bottom: 13px;

	// 	@include font(700, 'FiraSansCondensed');
	// 	font-size: 18px;
	// 	line-height: 22px;
	// 	padding-right: 180px;

	// 	color: $color-grape;


	// 	.caption__units,
	// 	.b-caption__units {
	// 		font-weight: normal;
	// 	}
	// }
	figcaption {
		border-bottom: 1px solid $color-black-magic;//$color-jacksons-purple;

		// [role="dialog"] & {
		// 	border-bottom: 0;
		// 	padding-left: 0;
		// 	margin-left: 0;
		// 	margin-bottom: 0;
		// 	padding-bottom: 11px;

		// 	&:before,
		// 	&:after {
		// 		display: none;
		// 	}
		// }

		@include font(600, 'FiraSansExtraCondensed'); // ЗАМЕНИТЬ НА 500
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: $color-black-magic;//$color-jacksons-purple;
		position: relative;
		padding-left: 59px;
		margin-left: 13px;
		padding-bottom: 7px;
		margin-bottom: 50px;
		font-weight: 500;
		text-transform: uppercase;

		&:before {
			content: "";
			border: 1px solid $color-black-magic;//$color-jacksons-purple;
			border-radius: 100%;
			width: 26px;
			height: 26px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translate(-50%, 50%);
		}

		&:after {
			content: "";
			background:  $color-black-magic;//$color-jacksons-purple;
			border-radius: 100%;
			width: 14px;
			height: 14px;
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			transform: translate(-50%, 50%);
		}

		.caption__units {
			font-weight: normal;
		}
	}

	table {
		min-width: 100%;

		thead {
			//Ячейки в заголовках
			td,
			th {
				color: $table--header-color;
				background-color: $table--header-bg;
				text-align: right;
				@include font(600, 'FiraSansExtraCondensed');
				font-size: 16px;
				line-height: 19px;
				border-bottom: 0;
				padding: 13px 11px 17px;
				height: auto;

				color: #000000;
				text-transform: uppercase;

				// &[colspan] {
				// 	text-align: center;
				// }
			}

			tr {
				th:first-child,
				td:first-child,
				th:nth-child(2),
				td:nth-child(2) {
					text-align: left;
				}
			}
		}

		tbody {
			tr {
				&:hover {
					background-color: $table-row-bg-hover;

					.b-cell--strong {
						background-color: $table-row-bg-strong-hover;
					}
				}
			}
		}

		//Обычные ячейки (не в заголовках)
		td,
		th {
			position: relative;
			text-align: right;
			border-bottom: 1px solid #C1C0C0;
			@include rem(padding, 10px 15px);
			transition: background-color 0.2s linear;

			@include font(300, 'Roboto');
			font-size: 15px;
			line-height: 18px;
			padding: 11px 13px 11px;
			// height: 64px;

			color: #575756;

			& > ul li,
			& > ol li,
			#colorbox & > ul li,
			#colorbox & > ol li  {
				color: #575756;
			}

			&:first-child,
			&:nth-child(2) {
				text-align: left;
			}
			&:first-child {
				// width: 34%;
				width: auto;
			}
			&:nth-child(2) {
				//width: 16%;
			}
		}
	}

	//Подзаголовок
	.b-subhead {
		td {
			@include font(600, 'Roboto');
			color: $color-black;
			// border-bottom-style: dotted;
		}
	}

	.b-subhead--level-1 td:first-child {
		padding-left: 0;
	}

	.b-subhead--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-subhead--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	//Выделеная ячейка
	.b-cell--strong {
		color: $table--strong-cell-color;
		background-color: $table--strong-cell-bg;
	}

	.b-cell--strong + .b-cell--strong {
		&:before {
			@include pseudo(1px, 100%, '');
			background-color: $table--strong-cell-border;
			top: 0;
			left: 0;
		}
	}

	.b-cell--level-1 {
		padding-left: 0;
	}

	.b-cell--level-2 {
		@include rem(padding-left, 25px);
	}

	.b-cell--level-3 {
		@include rem(padding-left, 50px);
	}

	.b-cell--regular {
		font-weight: 400 !important;
	}

	.b-cell--bold {
		font-weight: 700 !important;
	}

	//Выделеная строка
	.b-row--strong {
		color: $table--strong-row-color;
		background-color: $table--strong-row-bg;

		td {
			color: #005698;
			border-bottom-style: dotted;
			@include font(600, 'Roboto');
		}

		.is-active,
		.is-hover {
			background-color: $table--strong-row-bg-js;
		}

		&:hover {
			background-color: $table--strong-row-bg-hover;
		}
	}

	//Выбранная строка(по клику)
	.b-selected-row {
		td {
			background-color: $table--selected-row-bg !important;
		}

		.b-cell--strong {
			background-color: $table--selected-row-bg-strong !important;
		}
	}

	//Итоговая строка
	.b-summary {

		td {
			@include font(500, 'Roboto');
			font-size: 16px;
			line-height: 19px;
			height: auto;
			color: $color-black-magic;
			font-size: 15px;
			line-height: 18px;

			&:nth-child(2) {
				text-align: right;
			}

			&:first-child {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					left: 0;
					height: 100%;
					width: 5px;
					background-color: $color-tangerine-yellow;
					top: 0;
				}
			}
		}
	}

	.b-summary--level-1 td:first-child {
		padding-left: 0;
	}

	.b-summary--level-2 td:first-child {
		@include rem(padding-left, 25px);
	}

	.b-summary--level-3 td:first-child {
		@include rem(padding-left, 50px);
	}

	.b-fixed-head {
		overflow: hidden;
		z-index: 10;
		visibility: hidden;
		position: fixed;
		top: 0;
		left: -99999px;
		margin-top: 0;
		background-color: #fff;
	}

	// Цвет всего столбца, по наведению на ячейку
	.is-hover {
		background-color: $color-eallery;
	}

	// Цвет всего столбца, по клику на заголовок столбца
	.is-active {
		background-color: $color-eallery;
	}
}

.b-table--simple {
	table {
		thead {
			th,
			td {
				text-align: left;
			}
		}

		td,
		th {
			text-align: left;
		}
	}
}

// Классы масштабирования
@for $i from 1 through 9 {
	.b-table--scale-#{$i},
	.b-table--auto-scale-#{$i} {
		table {
			td,
			th {
				font-size: 1em - $i / 10 !important;
				padding-left: 1em - $i / 10 !important;
				padding-right: 1em - $i / 10 !important;
			}
		}
	}
}

// Кнопка, скрывающая/раскрывающая таблицу
.b-table__toggle-visible-rows-trigger {
	display: inline-block;
	height: 27px;
	text-decoration: none;
	@include webfont-icon($webfont-icon--i-table);
	position: absolute;
    padding: 0;
	bottom: 0;
	
	@include font(500, 'Roboto');
	font-size: 16px;

	color: $color-star-dust;
	transition: color 0.5s;
	margin-left: -4px;
	background-color: transparent;

	&:before {
		width: 27px;
		height: 27px;
		display: inline-block;
		background: transparent;
		line-height: 30px;
		font-size: 18px;
		color: $color-star-dust;
		margin-right: 3px;
		position: relative;
		top: -2px;
		vertical-align: middle;

		transition: color 0.5s, background 0.5s;
	}

	&:hover,
	&:focus {
		color: $color-zambezi;
		&:before {
			background: $color-china-ivory;
			color: $color-zambezi;
		}
	}

	&:active {
		color: $color-zambezi;
		&:before {
			background: $color-sunglow;
			color: $color-zambezi;
		}
	}

	&.is-expand {
		&:before {
			transform: rotate(180deg);
			line-height: 31px;
		}
	}
}

// Стили для таблицы с горизонтальным скроллом
.b-table {
	&__content {
		overflow: auto;
	}

	&__content-wrapper {
		position: relative;
	}
}

.b-table__content,
.b-table {
	::-webkit-scrollbar {
		height: 14px;
	}

	::-webkit-scrollbar-track {
		background: $color-white;
	}

	::-webkit-scrollbar-thumb {
		background: #919CAB;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		@include bg-rgba(145, 156, 171, 0.7);
	}
}

.b-fixed-scrollbar {
	display: none;
	overflow-x: scroll;
	position: fixed;
	width: 100%;
	margin-top: 0;
	z-index: 3;

	div {
		background: rgba(255, 255, 255, 0.01);
	}
}

.b-table--fixed-scrollbar {
	position: relative;
	overflow: hidden;
	@extend .b-table--scale-1;

	table {
		// margin-top: -2px;
		// margin-bottom: 2px;

		.browser-safari & {
			margin-bottom: 0;
		}
	}

	.b-scroll-arrow {
		position: absolute;
		height: calc(100% - 14px);
		width: 30px;
		top: 0;
		margin-top: 0;
		z-index: 1;
		transition: opacity 0.3s ease, transform 0.3s ease;
		cursor: pointer;
		opacity: 1;
		font-size: 16px;

		// &:not(.disabled):hover {
		// 	opacity: 0.7;
		// }

		// &.disabled {
		// 	opacity: 0.4;
		// }

		&.loaded {
			transform: none;
		}

		#colorbox & {
			height: calc(100% - 1.125em);
		}
	}

	.b-scroll-arrow__icon {
		position: absolute;
		transform: translateY(-50%);
		color: #000000;
	}

	.b-scroll-arrow--left {
		// background: linear-gradient(90deg, rgba(97, 110, 121, 1), rgba(255, 255, 255, 0));
		left: 0;
		transform: translateX(-100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(253, 201, 10, 1), rgba(255, 255, 255, 0));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--prev);
			margin-left: 5px;
		}
	}

	.b-scroll-arrow--right {
		// background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(97, 110, 121, 1));
		right: 0;
		transform: translateX(100%);

		&:not(.disabled):active {
			background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(253, 201, 10, 1));
		}

		.b-scroll-arrow__icon {
			@include webfont-icon($webfont-icon--next);
			margin-left: 12px;
		}
	}
}

@if ($responsive) {
	@media (min-width: $breakpoint-sm + 1px) {
		.b-table--no-xls .button--excel {
			display: none;
		}
	}

	@include breakpoint(sm) {
		.b-table {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			figcaption {
				padding-left: 12px;
			}

			table {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				@include font-size(20px);
			}
		}

		.figure-buttons .button--zoom,
		.b-table__toggle-visible-rows-trigger {
			display: none;
		}
	}
}

.body--table-30-50-20 {
	.td--th {
		color: #000000 !important;
		background-color: #FFCD00 !important;
		text-align: right;
		font-weight: 600 !important;
		@include font-size(16px);
		line-height: 19px;
		border-bottom: 0;
		padding: 13px 11px 17px;
		height: auto;
		text-transform: uppercase;
	}
}