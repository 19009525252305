$button-width: 33.333%;
$icon-side-margin: 5px;
$button-side-padding: 10px;

// Стили для случая, когда page-nav зафиксирован при скролле
.b-fixed-area {
	@extend %wrapper;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
	text-align: center;
	.b-pagenav__centred {
		padding: 0 5px;
		background: #fff;
	}

	@include breakpoint(sm) {
		padding: 0 10px;
	}
}

// .b-fixed-area--above-footer {
// 	bottom: $footer-height;
// }

// Стили для случая, когда page-nav находится внизу страницы
.b-now-static-area {
	position: relative;
	width: 100%;

	@include breakpoint(sm) {
		bottom: 27px !important;
	}
}

.b-pagenav--bottom {
	.b-pagenav {
		&__centred {


			position: relative;
			@extend %clearfix;

			border-top: 1px solid #F2F2F2;
		}

		&__link-content-wrapper {
			display: flex;
			align-items: center;
			width: 100%;
			// text-overflow: ellipsis;
			// white-space: nowrap;
			overflow: hidden;
		}

		&__link--prev {
			float: left;
			text-align: left;

			.b-icon--prev {
				@include webfont-icon($webfont-icon--i-arrow-left);
			}
		}

		&__link--next {
			float: right;
			text-align: right;

			.b-icon--next {
				order: 1;
				@include webfont-icon($webfont-icon--i-arrow-right);
			}

			.b-pagenav__link-content-wrapper {
				justify-content: flex-end;
			}
		}

		&__link {
			display: flex;
			align-items: center;
			position: relative;
			height: 40px;
			padding: 0;
			background-color: #fff;
			transition: background-color 0.2s ease;
			width: $button-width;
			text-decoration: none;
			
			@include font(400, 'Roboto');
			font-size: 13px;
			line-height: 13px;
			color: #9D9D9C;

			.b-icon:before {
				@include font-size(11px);
				color: #444345;
			}

			&:hover,
			&:focus,
			&.is-touch {
				color: #302884;
				.b-icon:before {
					color: #302884;
				}

				.b-pagenav__title {
					left: 0;
					opacity: 1;
				}
			}

			&:active {
				color: #FFD733;
				.b-icon:before {
					color: #FFD733;
				}
			}

			.b-icon--prev {
				margin-right: 10px;
			}

			.b-icon--next {
				margin-left: 10px;
			}
		}

		&__scrollup-wrapper {
			@extend %center-position;
			width: $button-width;
			background-color: $pagenav--btn-bg;


			.b-pagenav__scrollup {
				@extend .b-pagenav__link;
				text-align: center;
				width: 100%;
				cursor: pointer;

				.b-icon--scrollup {
					@include webfont-icon($webfont-icon--i-scrollup);
					margin: auto;
					left: 0;
					right: 0;
	
					&:before {
						color: #444345;
						@include font-size(6px);
					}
				}

				.b-pagenav__text--scrollup {
					display: none;
				}
			}
		}


		&__title {
			display: block;
			position: absolute;
			width: 100%;
			@include rem(bottom, $pagenav--link-height);
			left: -9999px;
			line-height: $base-line-height;
			color: $pagenav--title-color;
			background-color: $pagenav--title-bg;
			@include font-size(13px);
			margin: 0;
			@include rem(padding, 15px);
			opacity: 0;
			transition: opacity 0.2s ease;

			.b-pagenav-help {
				display: block;
			}
		}

		// Стили, применяемые к неактивным кнопкам пагинации
		&__link--disabled {
			opacity: 0.3;
			pointer-events: none;
			cursor: default;

			&:hover {

				.b-pagenav__title {
					left: -9999px;
					opacity: 0;
				}
			}

			&:active {
				background-color: $pagenav--btn-bg;
			}
		}
		@include breakpoint(sm) {
			&__link {
				height: 24px;
			}
		}
	}
}

.b-pagenav--animate-links {
	.b-pagenav {
		&__link-content-wrapper {
			height: 100%;
			position: relative;
			overflow: visible;
		}

		&__scrollup-wrapper {
			.b-pagenav__scrollup {
				height: 100%;
			}

			.b-icon--scrollup {
				@include rem(bottom, 20px);
			}
		}

		&__direction {
			display: block;
			@include font-size(25px);
			position: relative;
			@include rem(top, 13px);
			transition: all 0.4s ease-in-out;
		}

		&__text {
			@include rem(bottom, -25px);
			transition: all 0.4s ease-in-out;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			width: 100%;
			position: absolute;
		}

		&__text--scrollup {
			left: 0;
			bottom: 0;
		}

		&__link {
			@include rem(height, $pagenav--link-height * 2);
			overflow: hidden;

			.b-icon--prev {
				@include rem(left, -45px);
				transition: all 0.4s ease-in-out;
			}

			.b-icon--next {
				@include rem(right, -45px);
				transition: all 0.4s ease-in-out;
			}

			&:hover {
				.b-icon--prev {
					@include rem(left, -20px);
				}

				.b-icon--next {
					@include rem(right, -20px);
				}

				.b-pagenav__direction {
					@include font-size(16px);
					@include rem(top, 2px);
				}

				.b-pagenav__text {
					bottom: 0;
				}

				.b-pagenav__text--next {
					left: 0;
				}
			}
		}
	}
}

.b-pagenav--loading {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

.pagenav--hidden-up {
	.b-pagenav__scrollup-wrapper {
		display: none;
	}

	.b-pagenav__link {
		width: 50%;
	}
}

.b-pagenav--expandable {
	.b-pagenav {
		&__link {
			width: 50%;
		}

		&__expandable-button {
			@include webfont-icon($webfont-icon--menu);
			background-color: $pagenav--btn-bg;
			width: 40px;
			border-radius: 50%;
			transform: translateY(17px);
			color: $color-taupe-gray;
			@include rem(padding-bottom, 15px);

			&:hover,
			&:active,
			&:focus {
				color: $color-taupe-gray;
			}
		}

		.b-icon {
			position: absolute;
		}

		&__link--disabled {
			opacity: 1;
			.b-icon {
				display: none;
			}
		}
	}

	.is-open {
		transition: all 0.4s ease-in-out;
		bottom: 0;
	}

	.is-close {
		transition: all 0.4s ease-in-out;
		@include rem(bottom, -24px);
	}
}

@if ($responsive) {
	@include breakpoint(sm) {
		.b-pagenav--animate-links {
			.b-pagenav {
				&__scrollup-wrapper {
					.b-pagenav__scrollup {
						height: 100%;
					}

					.b-icon--scrollup {
						bottom: 0;
					}
				}

				&__direction {
					display: none;
				}

				&__text {
					display: none;
				}

				&__link {
					@include rem(height, $pagenav--link-height);
					.b-icon--prev {
						@include rem(left, 10px);
					}

					.b-icon--next {
						@include rem(right, 10px);
					}
				}
			}
		}

		.b-pagenav--bottom {
			.b-pagenav {
				&__text {
					display: none;
				}
			}
		}
	}
}
