$accordion--controls-height: 48px;
$accordion--button-bg: $color-white-smoke;
$accordion--button-bg-hover: $color-whisper;
$accordion--button-bg-active: $color-tangerine-yellow;
$accordion--button-text-color: $color-black;
$accordion--button-text-color-hover: $color-black;
$accordion--button-text-color-active: $color-black;
$accordion--article-bg: $color-white;
$accordion--article-close-hover: orange;
$accordion--control-icon-bg: $color-navy-blue;
$accordion--control-icon-color: $color-white;
$accordion--control-item-bg: $color-celeste;
$accordion--control-item-bg-hover: $color-ironside-gray;
$accordion--control-item-bg-active: $color-mine-shaft;
$accordion--control-text-color: $base-text-color;
$accordion--control-text-color-hover: $color-white;
$accordion--control-text-color-active: $color-white;
$accordion--article-close-bg: $base-text-color;
$accordion--article-close-bg-hover: $color-navy-blue;
$accordion--article-close-bg-active: $color-prussian-blue;
$accordion--button-font-size: 20px;

%control-icon {
	content: '';
	display: block;
	position: absolute;
	background-color: $accordion--control-icon-color;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	@include rem(width, 20px);
	@include rem(height, 2px);
}

.b-accordion {
	margin-bottom: 88px;

	&__section {
		@include rem(margin-bottom, 1px);
	}

	&__button {
		display: block;
		position: relative;
		padding: 13px 50px 13px 26px;
		text-decoration: none;
		color: $accordion--button-text-color;
		background-color: $accordion--button-bg;
		@include font(600, 'FiraSansExtraCondensed');
		font-size: 18px;
		line-height: 22px;
		cursor: pointer;
		transition: all .2s ease-in-out;

		@include webfont-icon($webfont-icon--i-arrow-down);

		&:before {
			position: absolute;
			display: block;
			@include rem(width, 24px);
			@include rem(height, 8px);
			font-size: 9px;
			color: $accordion--button-text-color;
			top: 0;
			bottom: 0;
			margin: auto;
			right: 13px;
			transition: transform 0.2s ease;
		}

		&:visited {
			color: $accordion--button-text-color;
			@include font(600, 'FiraSansExtraCondensed');

			&:focus {
				color: $accordion--button-text-color;
			}
		}

		&:hover,
		&:focus {
			color: $accordion--button-text-color-hover;
			background-color: $accordion--button-bg-hover;
			@include font(600, 'FiraSansExtraCondensed');

			&:before {
				color: $accordion--button-text-color-hover;
			}

			&:visited {
				color: $accordion--button-text-color-hover;
				background-color: $accordion--button-bg-hover;
			}
		}

		&.is-expand,
		&:active {
			color: $accordion--button-text-color-active;
			background-color: $accordion--button-bg-active;
			@include font(600, 'FiraSansExtraCondensed');

			&:before {
				color: $accordion--button-text-color-active;
			}

			&:visited {
				color: $accordion--button-text-color-active;
				background-color: $accordion--button-bg-active;
			}
		}

		&.is-expand {
			&:before {
				transform: rotate(180deg);
			}
		}

		.title--analysis & {
			@include font(300, Roboto);
			font-size: 16px;
			line-height: 17px;
			padding-left: 10px;
			padding-top: 10px;
			padding-bottom: 5px;
		}

	}

	&__article {
		position: relative;
		margin-top: 0;
		background-color: $accordion--article-bg;
		display: none;
		padding: 22px 18px 2px 26px;
		@include font(300, 'Roboto');
		font-size: 16px;
		line-height: 21px;

		color: $color-black;

		&.is-expand {
			display: block;

			.b-accordion__article-close {
				&:before {
					transform: rotate(45deg);
				}

				&:after {
					transform: rotate(-45deg);
				}
			}
		}
	}


	&__control {
		@include rem(padding-top, 10px);
	}

	&__control-item {
		display: inline-block;
		@include rem(height, $accordion--controls-height);
		@include rem(line-height, $accordion--controls-height);
		@include rem(margin-bottom, 10px);
		background-color: $accordion--button-bg;
		color: $accordion--control-text-color;
		padding: 0;
		cursor: pointer;

		&:first-child {
			@include rem(margin-right, 10px);
		}

		&:hover {
			background-color: $accordion--control-item-bg-hover;
			color: $accordion--control-text-color-hover;
		}

		&:active {
			background-color: $accordion--control-item-bg-active;
			color: $accordion--control-text-color-active;
		}
	}

	&__control-icon {
		display: block;
		position: relative;
		height: 100%;
		@include rem(width, $accordion--controls-height);
		float: left;
		background-color: $accordion--control-icon-bg;
	}

	&__control-text {
		@include rem(padding-left, 15px);
		@include rem(padding-right, 15px);
	}

	&__control-item--expand {
		.b-accordion__control-icon {
			&:before,
			&:after {
				@extend %control-icon;
			}

			&:before {
				transform: rotate(0deg);
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}

	&__control-item--collapse {
		.b-accordion__control-icon:before {
			@extend %control-icon;
		}
	}
}
