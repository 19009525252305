.header-1 {
	@include font(600, 'FiraSansExtraCondensed');
	font-size: 26px;
	line-height: 31px;
	margin-bottom: 18px;
	margin-top: 0;

	color: $color-black-magic;
	color: #302A24;
	border-bottom: 3px solid #FFCD00;
	display: inline-block;

	.title & {
		border: 0;
		font-size: 20px;
		line-height: 24px;
	}

	.title--download-center & {
		font-size: 20px;
		line-height: 24px;
		color: #302A24;
		border-bottom: 0;
	}

	.title--analysis & {
		margin-bottom: 22px;
	}
}

h1 {
	@extend .header-1;
}

.header-2 {
	@include font(400, 'FiraSansExtraCondensed');
	font-size: 20px;
	line-height: 24px;
	margin-bottom: 23px;
	margin-top: 0;

	color: #005698;

	text-transform: uppercase;

	@if ($responsive) {
		@include breakpoint(sm) {
			line-height: 1;
		}
	}
}

h2 {
	@extend .header-2;
}

.header-3 {
	@include font(400, 'FiraSansExtraCondensed');
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 25px;
	margin-top: 0;
	color: $color-black-magic;

	text-transform: uppercase;

	color: #302A24;

	i {
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		&:before {
			font-size: 21px;
			color: #FFCD00;
		}
	}
}

h3 {
	@extend .header-3;
}

.header-4 {
	@include font(400, 'FiraSansExtraCondensed');
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 10px;
	margin-top: 0;
	color: #005698;

}

h4 {
	@extend .header-4;
}

.header-5 {
	@include font(600, 'FiraSansExtraCondensed');
	font-size: 16px;
	line-height: 21px;
	margin-bottom: 20px;
	color: $color-black-magic;
}

h5 {
	@extend .header-5;
}

.header-6 {
	@include font-size(16px);
	font-weight: bold;
	margin-bottom: 1.2em;
}

h6 {
	@extend .header-6;
}

.header-icon {
	display: flex;
	align-items: center;
	margin-bottom: 0.83em;

	&__icon {
		margin-right: 12px;
	}

	&__title {
		margin: 0;
	}
}
