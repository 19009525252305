$header-bg: $color-white;
$body-bg: $color-white;
$footer-bg: $color-white;


body {
	background-color: $body-bg;
	overflow-x: hidden;
}

.l-layout-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: $site-width;
	margin: 0 auto;
	justify-content: space-between;

	&__header {
		flex: none;
		@include rem(padding-bottom, 10px);
		margin-bottom: 24px;

		&:before {
			content: '';
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: $header-bg;
			z-index: -1;
		}
	}

	&__footer {
		background-color: $footer-bg;
		flex: none;
	}
}

.l-page {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	flex-shrink: 0;
	min-height: 1px;
	max-width: $site-width;
	width: 100%;
	margin: 0 auto;
	.browser-opera-23 & {
		align-items: flex-start;
	}

	&__sidebar {
		flex-grow: 0;
		flex-shrink: 0;

		.title--analysis & {
			display: none;
		}
	}

	&__sidebar--left {
		flex-basis: $left-col-width;
		width: $left-col-width;
		min-width: $left-col-width;
	}

	&__sidebar--right {
		flex-basis: 331px;//$right-col-width;
		width: 331px;//$right-col-width;
		min-width: 331px;//$right-col-width;
		padding-left: 27px;

		.browser-ie & {
			flex-basis: 304px;//$right-col-width;
			width: 304px;//$right-col-width;
			min-width: 304px;//$right-col-width;
		}
	}

	&__sidebar-section {
		@include rem(margin-bottom, 10px);
	}

	&__sidebar-section--adaptive-menu {
		display: none;
	}

	&__content {
		// padding-bottom: 3em;
		padding-left: $grid-gutter-width;
		padding-right: $grid-gutter-width;
		flex-shrink: 1;
		flex-grow: 1;
		position: relative;
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__content--with-left {
		padding-left: 0;
		width: 934px;

		.title--analysis & {
			padding-right: 0;
		}

		.browser-ie & {
			width: 1014px;
		}
	}

	&__content--with-right {
		padding-right: 0;
		width: $right-col-width + $center-col-width;
	}

	&__content--wide {
		padding-right: 0;
		padding-left: 0;
		width: 100%;
	}

	&__nav {
		display: flex;
		align-items: center;
		margin-bottom: 34px;
	}

	&__nav-breadcrumbs {
		flex-grow: 1;
	}

	&__nav-controls {
		flex-grow: 0;
		flex-shrink: 0;
	}

	&__pdf {
		color: $color-star-dust;
		font-size: 14px;
		line-height: 16px;
		@include font(500, "Roboto");
		@include webfont-icon($webfont-icon--i-download-pdf, after);
		text-decoration: none;

		&::after {
			font-size: 16px;
			margin-left: 5px;
			display: inline-block;
			vertical-align: middle;
			padding: 4px;
    		margin-top: -4px;
		}

		&:hover {
			color: #5F5E5E;

			&:after {
				color: #5F5E5E;
				background-color: #FFEB99;
			}
			
		}

		&:active {
			color: #5F5E5E;

			&:after {
				color: #5F5E5E;
				background-color: #FFCD00;
			}
		}
	}

	.title--search & {
		max-width: 864px;
		padding: 32px 0 0 0;
	}

	&__title {
		.title--search & {
			display: none;
		}
	}
}

a.l-page__pdf {
	&:active {
		color: #5F5E5E;
	}
}

.l-page-nav--mobile {
	display: none;
}

.l-layout-wrapper__page,
.l-layout-wrapper__footer {
	padding-left: 40px;
	padding-right: $grid-gutter-width;
}

.l-layout-wrapper__page {
	padding-left: 0;
	padding-right: 0;
	left: 0;//50px;
	position: relative;
	max-width: 1361px;
}


.title--analysis {
	.l-layout-wrapper__page {
		padding: 0;
		max-width: 1024px;
	}
}

.content-area {
	min-height: 1px;
	height: 100%;

	.title--download-center & {
		max-width: 944px;
		margin: 0 auto;
		padding-top: 36px;
		width: 100%;
	}

	.browser-opera-23 & {
		height: auto;
	}
}

.l-content--with-left {
	margin-left: -($left-col-width + 15px);
}

.l-content--with-right {
	margin-right: -($right-col-width + 15px);
}

.aside {
	position: absolute;
	margin-top: 0;
}

.aside--right {
	// width: $right-col-width;
	// right: -$right-col-width;
	padding-left: $grid-gutter-width;
	width: 235px;
    right: -296px;
}

.aside--left {
	width: $left-col-width;
	left: -$left-col-width;
	padding-right: $grid-gutter-width;
}

@if ($responsive) {
	.l-layout-wrapper {
		width: 100%;
		min-width: $site-min-width;
	}

	@include breakpoint(md) {
		.l-layout-wrapper__header {
			// padding-left: $grid-gutter-width;
			// padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__page {
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;
		}

		.l-layout-wrapper__footer {
			// padding-left: $grid-gutter-width;
			// padding-right: $grid-gutter-width;
		}

		.l-page {
			&__content {
				width: auto;
			//overflow: hidden;
			}

			&__sidebar--right {
				flex-basis: 231px;
				width: 231px;
				min-width: 231px;
				padding-left: 0;
			}
		}

		.aside--right {
			padding-left: 16px;
			width: 200px;
			right: -235px;
		}

		.content-area {
			.title--analysis & {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	@include breakpoint(sm) {
		.l-page__content {
			padding-left: 0;
			padding-right: 0;
		}

		.l-page {
			overflow-x: hidden;
		}

		.l-page__sidebar-section--menu {
			display: none;
		}

		.l-page__sidebar-section--adaptive-menu {
			display: block;
		}

		.l-page-nav {
			display: none;

			&--mobile {
				display: block;
			}
		}

		.l-page__nav {
			display: none;
		}

		.aside {
			position: static;
			width: 100%;
			padding-left: 0;
			padding-right: 0;
		}

		.content-area {
			.title--analysis & {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}
