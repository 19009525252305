.b-bod {
    &__wrapper {
        border: 2px solid #FFCD00;
        padding: 15px;
        margin-bottom: 10px;
    }
    &__name {
        @include font-size(20px);
        color: #444345;
        border-bottom: 2px solid #444345;
        padding-bottom: 11px;
        width: calc(100% + 30px);
        margin-left: -15px;
        div {
            margin-left: 15px;
            font-weight: 700;
        }
    }
    &__content {
        &--birth {
            margin-top: 10px;
            margin-bottom: 10px;
            display: block;
        }
        p {
            span {
                color: #005698;
                font-weight: 700;
            }
        }
    }
}