.body--page-error {

	.l-layout-wrapper__header {
		padding-bottom: 0;
		margin-bottom: 0;
	}

	.l-page {
		max-width: none;
		width: 100%;
		height: 100%;
		background-color: $color-tangerine-yellow;
	}

	.l-page__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 30px 20px 50px;
	}
	.l-page-nav {
		display: none;
	}
}

.b-page-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 100%;

	&__code {
		display: block;
		margin-bottom: 45px;
		@include font(500, "FiraSansExtraCondensed");
		font-weight: 500;
		font-size: 288px;
		line-height: 288px;
		color: $color-white;
		text-align: center;
	}

	&__text {
		display: block;
		width: 100%;
		max-width: 430px;
		margin: 0 auto 22px;
		@include font(400);
		font-weight: 400;
		font-size: 18px;
		line-height: 23px;
		color: $color-jacksons-purple;
		text-align: center;
	}

	&__link {
		@include font(500);
		font-weight: 500;
		font-size: 18px;
		line-height: 23px;
		color: $color-jacksons-purple;
		text-decoration: underline;

		&:visited {
			color: $color-jacksons-purple;
		}

		&:hover,
		&:focus,
		&:visited:hover,
		&:visited:focus {
			color: $color-white;
			text-decoration: underline;
		}

		&:active,
		&:visited:active {
			color: $color-white;
			text-decoration: none;
		}
	}

	&__icon {
		display: block;
		margin: 45px 0 0;
		position: relative;
		@include webfont-icon($webfont-icon--page-error);

		&:before {
			font-size: 135px;
			color: $color-white;
		}

		&:after {
			content: '';
			width: 100vh;
			height: 24px;
			background: $color-white;
			position: absolute;
			left: calc(-100vh + 2px);
			top: 62px;
		}
	}
}

@include breakpoint(sm) {
	.body--page-error {
		.l-page {
			padding: 0;
		}
	}

	.b-page-error__code {
		margin-bottom: 25px;
		font-size: 200px;
		line-height: 200px;
	}

	.b-page-error__text {
		margin-bottom: 14px;
		font-size: 16px;
	}

	.b-page-error__link {
		font-size: 16px;
	}

	.b-page-error__icon {
		margin: 47px 0 0;

		&:before {
			font-size: 120px;
		}

		&:after {
			height: 20px;
			top: 55px;
		}
	}
}

@include breakpoint(xs) {
	.body--page-error {
		.l-page {
			padding: 0;
		}
	}

	.b-page-error__code {
		font-size: 130px;
		line-height: 130px;
	}

	.b-page-error__icon {
		margin: 40px 0 0;

		&:before {
			font-size: 85px;
		}

		&:after {
			height: 14px;
			top: 40px;
		}
	}
}
