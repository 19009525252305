$button-size: 21px;
$analysis-bg-color: #cdcdcd;
$analysis-btn-bg-hover: #444444;
$analysis-icon-color: #33ffff;
$analysis-icon-color-hover: $color-white;

@if ($contrast) {
	$analysis-bg-color: #ededed;
	$analysis-btn-bg-hover: #656565;
	$analysis-icon-color: #656565;
	$analysis-icon-color-hover: $color-white;
}

.analysis__preset {
	@include rem(padding, 5px 10px);
	@include rem(margin, 0px 1px 5px 1px);

	&:not(.is-selected) {
		background-color: $analysis-bg-color;
	}
}

.analysis__presets-list {
	display: none;
}

.analysis__main {
	display: table;
	table-layout: fixed;
	width: 100%;
}

.analysis__chart-section {
	display: table-cell;
	vertical-align: top;
	padding-left: 50px;
}

.analysis__chart {
	margin-bottom: 30px;

	.c3-tooltip {

		th {
			background-color: transparent;
			@include font(500, Roboto);
			font-size: 11px;
			line-height: 22px;
			border-bottom: 2px solid $color-black;
			color: $color-black;
		}

		td {
			@include font(400, Roboto);
			font-size: 11px;
			line-height: 13px;
			border-bottom: 1px solid $color-star-dust;
			border-left: 0;
			padding: 5px 6px;
		}
	}
}

.analysis__chart-section,
.analysis__items-list {
	float: none !important;
}

.analysis__items-list {
	width: 304px;
	display: table-cell;
	vertical-align: top;

	.items-group__title {
		background-color: $analysis-bg-color;
		@include font-size(10px);
	}

	.items-group__item {
		@include rem(padding, 5px 5px 5px 10px);
		@include font-size(14px);
		text-indent: 0;
		border: 1px solid $color-white;

		&.is-even:not(.is-selected):not(:hover) {
			background-color: #ececec;
		}

		&:hover {
			background-color: $color-drover;
			color: $color-black;
		}

		&.is-selected {
			background-color: $color-tangerine-yellow;
			color: $color-black;
		}

		&.subgroup {
			@include rem(padding-left, 30px);
		}
	}

	.b-accordion__article {
		background: transparent;
	}
}

.analysis__table {
	@include font-size(13px);

	thead {
		border-bottom: 2px solid $color-black;
	}

	th {
		background-color: transparent;
		@include rem(padding, 6px 10px);
		@include font(500, Roboto);
		font-size: 12px;
		line-height: 11px;

		&[title='Единица измерения'] {
			width: 32px;
			padding-right: 30px;
			white-space: pre-wrap;
			text-align: right;
		}

		&:not(:first-child) {
			text-align: right;
		}
	}

	td {
		@include rem(padding, 15px 5px);
		@include font(300, Roboto);
		font-size: 12px;
		line-height: 13px;
		border-bottom: 1px solid $color-wan-white;

		&.item__cell--units {
			width: 32px;
			padding-right: 30px;
			padding-left: 0;
		}

		&.item__value,
		&.item__cell--units {
			text-align: right;
		}

		&.item__cell--title {
			padding-right: 30px;
		}

		&.item__value {
			padding: 15px 13px;
		}
	}
}

.analysis__years-list {
	@include rem(margin-top, 40px);

	.years-list__year {
		@include rem(padding, 5px);
		@include font(600, Roboto);
		font-size: 12px;
		line-height: 22px;

		&:hover {
			color: $color-tangerine-yellow;
		}

		&:not(.is-selected) {
			// background-color: transparent;
			color: $color-star-dust;
		}
	}
}

.analysis__chart-area {
	// margin-top: 50px;

	.c3-axis-y2 {
		display: none;
	}
}

.analysis__buttons {
	white-space: nowrap;
	display: inline-block;
	margin: 0;
	$button-padding: 6px;
	$button-image-size: $button-size - $button-padding;

	.analysis__button {
		@include rem(width, $button-size);
		@include rem(height, $button-size);
		@include rem(padding, 1px);
		background-color: transparent;
		display: inline-block;
		color: $color-tapa-gray;

		&:hover {
			background-color: transparent;
			color: $color-gray;
		}

		&:before {
			@include font-size(21px);
		}

		&:not(:last-child) {
			margin-right: 16px;
		}
	}

	.analysis__button--line {
		@include webfont-icon($webfont-icon--i-chart);
		&:before {
			@include font-size(16px);
		}
	}

	.analysis__button--histogram {
		@include webfont-icon($webfont-icon--i-line);
		&:before {
			@include font-size(13px);
		}
	}

	.analysis__button--excel {
		display: none;
		// @include webfont-icon($webfont-icon--i-xls);
	}
}

.analysis__items-list .b-accordion__article {
	padding: 0 0 0 16px;
}

.c3-tooltip td.name {
	max-width: 700px;
}

@include breakpoint(md) {
	.analysis__main {
		display: block;
	}

	.analysis__items-list {
		display: block;
		width: 100%;
	}

	.analysis__chart-section {
		display: block;
		width: 100%;
		padding: 0;
	}
}

@include breakpoint(xs) {
	.analysis__table {
		@include rem(font-size, 8px)
	}

	.analysis__years-list {
		@include rem(font-size, 12px);
	}

	.c3-tooltip-container {
		left: 50% !important;
		transform: translateX(-50%);
		width: 320px;
	}

	.table {
		overflow-x: auto;
	}
}
