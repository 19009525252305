$preloader--size-width: 80px;
$preloader--size-height: 80px;
$preloader--bg-color: $color-white;
$preloader--bg-opacity: 0.9;
$preloader--color: #302884;

.preloader {
	min-height: $preloader--size-height * 2;
	min-width: $preloader--size-width * 2;
	z-index: 999999999;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		@include svg($preloader--size-height, $preloader--size-height, '/images/components/preloader/.variant/base/preloader.svg', $preloader--color, 'stroke');
		// background-position: center center;
		background-color: rgba($preloader--bg-color, $preloader--bg-opacity);
		z-index: 1;
	}
	&__svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;

		path {
			stroke-dasharray: 1000;
			stroke-dashoffset: 1000;
			animation: dash 2s linear alternate infinite;
		  }
		  
		  @keyframes dash {
			from {
			  stroke-dashoffset: 1000;
			}
			to {
			  stroke-dashoffset: 0;
			}
		  }
	}
}

.preloader--is-button {
	min-height: inherit;
	min-width: inherit;

	&:after {
		@include svg(auto, 80%, '/images/components/preloader/.variant/base/preloader.svg', $preloader--color, 'stroke');
	}
}

.preloader:not(.preloader--is-flow-item) {
	position: relative;
}

.feature-no-svg .preloader:after,
.browser-ie .preloader:after {
	background-image: url('/images/components/preloader/preloader.gif') !important;
	background-color: $color-white !important;
}
