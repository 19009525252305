.l-history {
	margin-top: 20px;
}

.b-history {
	display: none;

	&__date {
		@include font(600, "FiraSansExtraCondensed");
		font-size: 18px;
		line-height: 22px;
		margin-bottom: 17px;
		color: $color-black;
	}

	&__list {
		@include rem(margin-bottom, 35px);
	}

	&__item {
		@include rem(margin-top, 5px);
		display: flex;
		align-items: center;
	}

	&__link {
		color: #302884;
		@include font(400, "Roboto");

		&:visited {
			color: #302884;
		}
	}

	&__time {
		padding: 0 9px;
		font-size: 18px;
		line-height: 20px;
		color: #302884;
		background-color: #FFF5CC;
		margin-right: 15px;
		@include font(400, "FiraSansExtraCondensed");
	}

	&__btn {
		@include rem(margin-bottom, 18px);
	}
}

.b-history-mockup {
	width: 100%;

	&__inner {
		display: inline-flex;
		max-width: 450px;
	}

	&__icon {
		@include rem(margin-right, 15px);
		@include webfont-icon($webfont-icon--i-close);
		padding-top: 6px;

		&::before {
			font-size: 16px;
			color: #FF0000;
			font-weight: bold;
		}

		i {
			display: none;
		}

		& > * {
			@include rem(font-size, 70px);
		}
	}

	&__content {}
	&__message {
		font-size: 16px;
		line-height: 21px;
		@include font(300, "Roboto");
		color: $color-black;
		margin-bottom: 10px;
	}
	&__link {
		font-size: 16px;
		line-height: 21px;
		@include font(400, "Roboto");
		color: $color-jacksons-purple;
	}
}

// .b-history-empty {
// 	&:not(.preloader) {
// 		@extend %clearfix;
// 	}
//
// 	&__message {
// 		margin: 0;
// 	}
//
// 	&__image {
// 		float: left;
// 		width: 61px;
// 		height: 85px;
// 	}
//
// 	&__content {
// 		float: left;
// 		@include rem(margin-top, 20px);
// 		@include rem(margin-left, 25px);
// 	}
//
// 	&__link {
// 		display: inline-block;
// 		@extend %disable-visited;
// 		@include rem(margin-top, 25px);
// 	}
// }
