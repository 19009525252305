.b-logo {
	display: block;
	width: 43px;

	&__image {
		width: 100%;
		height: 100%;

		&--mobile {
			display: none;
		}
	}

	@include breakpoint(sm) {
		width: auto;
		height: 33px;

		&--ros {
			width: 30px;
		}
		&--main {
			width: 44px;
		}
		&__image {
			display: none;

			&--mobile {
				display: block;
			}
		}
	}
}

.logo-svg {
	width: 100%;
	height: auto;
}
