/* Links */

/**
 * Remove the gray background color from active links in IE 10.
 */

a {
	color: $color-jacksons-purple;
	background-color: transparent;
	transition: color 0.2s ease;
	@include font(400, 'Roboto');

	&:visited {
		color: $color-star-dust;
	}

	&:hover,
	&:focus,
	&:visited:hover,
	&:visited:focus {
		color: $color-zambezi;
		@include font(500, 'Roboto');
	}

	&:active,
	&:visited:active {
		color: $color-tangerine-yellow;
		@include font(500, 'Roboto');
	}

	&:active,
	&:hover {
		outline: 0;
		@include font(500, 'Roboto');
	}

	// &.btn {
	// 	&:visited {
	// 		color: $btn--color;
	// 	}

	// 	&:hover,
	// 	&:focus,
	// 	&:visited:hover,
	// 	&:visited:focus {
	// 		color: $btn--color-hover;
	// 	}

	// 	&:active,
	// 	&:visited:active {
	// 		color: $btn--color-active;
	// 	}
	// }
}

[data-anchor-link] {
	cursor: pointer;
}

.link--external {
	@include webfont-icon($webfont-icon--i-link);
	text-decoration: none;

	&:before {
		display: inline-block;
		@include font-size(7px);
		margin-right: 5px;
		vertical-align: middle;
		font-weight: normal;
	}
}

.link--phone {
	white-space: nowrap;
}

.b-link-icon {
	display: flex;
	//flex: 0 1 auto;
	align-items: center;

	&__icon {
		margin-right: 5px;
		flex-basis: 100%;
		max-width: 25px;
		min-width: 20px;
	}

	&__text {
		flex-shrink: 1;
	}
}

//иконка слева
.b-link-icon--left {
	.b-link-icon__icon {
		img {
			display: block;
		}
	}
}

//иконка справа
.b-link-icon--right {
	.b-link-icon__icon {
		margin-left: 5px;
		margin-right: auto;

		img {
			display: block;
		}
	}
}

//размеры иконок, при необходимости - добавлять модификаторы - xs, xm, xl

//по умолчанию без модификации
.b-link-icon--size-s {
}

//средний размер
.b-link-icon--size-m {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 30px;
	}
}

//большой размер
.b-link-icon--size-l {
	.b-link-icon__icon {
		flex-basis: 100%;
		max-width: 40px;
	}
}

.b-link-icon--size-free {
	.b-link-icon__icon {
		flex-basis: auto;
		max-width: initial;
	}
}
