$captcha_base--icon-color: $color-ironside-gray;
$captcha_base--icon-color-hover: $color-ironside-gray;
$captcha_base--icon-color-active: $color-ironside-gray;
$captcha_base--border-color: #aaaaaa;

@if ($contrast) {
	$captcha_base--icon-color: $color-white;
	$captcha_base--icon-color: $color-ironside-gray;
	$captcha_base--icon-color-hover: $color-ironside-gray;
	$captcha_base--icon-color-active: $color-ironside-gray;
	$captcha_base--border-color: #aaaaaa;
}

.b-captcha {
	&__reload-btn {
		background-color: transparent;
		color: $captcha_base--icon-color;
		padding: 0;
		margin-right: 15px;
		transition: transform 0.5s ease;
		@include webfont-icon($webfont-icon--i-reload);

		&:before {
			display: block;
			@include font-size(20px);
			color: #302884;
		}

		&:hover,
		&:focus {
			transform: rotate(-360deg);
		}

		&:active {
		}
	}

	&__data {
		display: flex;
		align-items: center;
	}

	&__image {
		border: 1px solid #9d9d9c;
	}
}
