$my-report_base--item-height: $input-height;
$my-report_base--item-bg: $color-celeste;
$my-report_base--item-move-bg: $color-gray;
$my-report_base--checkbox-icon: $color-celeste;
$my-report_base--checkbox-bg: $color-light-gray;
$my-report_base--checkbox-bg-checked: $color-white;
$my-report_base--checkbox-bg-hover: $color-dark-gray;
$my-report_base--link-color: $link-default-color;
$my-report_base--select-all: $base-text-color;

.l-my-report {
	&__mockcup {}

	&__content {
		.b-my-report {
			display: none;
		}
	}
}

.b-my-report-mockup {
	width: 100%;

	&__inner {
		display: inline-flex;
		max-width: 450px;
	}

	&__icon {
		@include rem(margin-right, 15px);
		@include webfont-icon($webfont-icon--i-close);
		padding-top: 6px;

		&::before {
			font-size: 16px;
			color: #FF0000;
			font-weight: bold;
		}

		i {
			display: none;
		}

		& > * {
			@include rem(font-size, 70px);
		}
	}

	&__content {}
	&__message {
		font-size: 16px;
		line-height: 21px;
		@include font(300, "Roboto");
		color: $color-black;
		margin-bottom: 10px;
	}
	&__link {
		font-size: 16px;
		line-height: 21px;
		@include font(400, "Roboto");
		color: $color-jacksons-purple;
	}
}

.b-my-report {
	&__inner {}

	&__rows {}

	&__row {
		text-align: left;
		padding: 10px;
		font-size: 16px;
		line-height: 21px;
		@include font(300, "Roboto");
		border-bottom: 1px solid #E2E1DE;
		display: flex;
		align-items: center;
		color: $color-black;
	}

	&__row-action + &__row-content {
		@include rem(padding-left, 15px);
	}

	&__row-content + &__row-action {
		@include rem(padding-left, 15px);
	}

	&__btn-group {
		margin-top: 31px;
		display: inline-flex;
		flex-wrap: wrap;
	}

	&__link {
		color: #302884;
		@include font(400, "Roboto");

		&:visited {
			color: #302884;
		}
	}

	&__btn-wrapper {
		@include rem(margin-right, 15px);
	}

	&__btn {
		// width: 9em;
	}
}

.sortable-ghost {
	background-color: $my-report_base--item-move-bg;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}


@if ($responsive) {
	@include breakpoint(sm) {
		.b-my-report {
			&__btn-group {
				width: 100%;
			}

			&__btn-wrapper {
				//flex-basis: 50%;
				//margin-right: 0;
				@include rem(margin-bottom, 15px);
			}
		}
	}

	@include breakpoint(xs) {
		.b-my-report {
			&__btn-wrapper {
				//flex-basis: 100%;
			}
		}
	}
}
