.lead {
	font-weight: bold;
	font-size: 1.1em;

	@include font(300, 'Roboto');
	font-size: 19px;
	line-height: 26px;
	margin-bottom: 40px;

	color: #000000;

	ul li {
		font-size: 19px;
		line-height: 26px;

		&::before {
			top: 9px !important;
		}
	}
}
