$menu_vertical-accordeon--border: #e2e8ec;

@if ($contrast) {
	$menu_vertical-accordeon--border: #ededed;
}

.b-nav--left.b-nav--vertical-accordeon {
	border-left: 1px solid #000000;

	.b-nav__list {
		.b-nav__list {
			display: none;
		}

		&.is-expand {
			display: block;
		}
	}
	.b-nav__item {
		position: relative;
		top: -1px;
	}

	.b-nav__link {
		text-decoration: none;
		@include font(400, 'Roboto');
		font-size: 14px;
		line-height: 16px;
		padding: 2px 5px;
		margin-bottom: 13px;
		display: inline-block;

		color: #5F5E5E;

		&:hover,
		&:focus,
		&.current,
		&:active {
			color: #000;
		}
	}

	.b-nav__link--lvl1 {
		&:before {
			background: #fff;
			border-radius: 100%;
			border: 1px solid #9D9D9C;
			width: 16px;
			height: 16px;
			content: "";
			display: block;
			position: absolute;
			left: -8px;
			top: 1px;
			transition: border-color 0.5s, background-color 0.5s;
		}

		&:after {
			background: #000;
			border-radius: 100%;
			width: 4px;
			height: 4px;
			content: "";
			display: block;
			position: absolute;
			left: -2px;
			top: 7px;
			opacity: 0;
			transition: opacity 0.5s;
		}


		&:hover,
		&:focus {
			color: #000;

			&:before {
				border-color: #000;
			}
			&:after {
				opacity: 1;
			}
		}

		&.current,
		&:active {
			color: #000;

			&:before {
				border-color: #000;
				background: #FFCD00;
			}
			&:after {
				opacity: 1;
			}
		}
	}

	.has-submenu {
		.icon-left-nav {
			// display: none;
			// position: absolute;
			// top: 0;
			// left: 0;

			position: relative;
			display: inline-block;
			width: 12px;
			height: 12px;
			margin: 0 0 0 3px;
			top: 1px;
			cursor: pointer;

			&:hover,
			&:focus,
			&:active {
				color: #302884
			}

			&:before,
			&:after {
				content: "";
				display: block;
				width: 12px;
				height: 1.5px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				background: #5F5E5E;
				transition: transform 0.1s;
			}
			&:after {
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}

		&.is-expand {
			.icon-left-nav:after {
				transform: translate(-50%, -50%);
			}
		}
	}

	.b-nav__item--lvl2 .b-nav__link {
		background: #fff;
		color: #5F5E5E;
		display: block;

		&:hover,
		&:focus {
			background: #FFEB99;
			color: #302884;
		}

		&:active,
		&.current {
			background: #FFCD00;
			color: #302884;
		}
	}

	.b-nav__list--lvl2 {
		margin: 6px 0;
	}
	.b-nav__list--lvl3 {
		margin: 6px 0;
	}
	.b-nav__item--lvl1 > .b-nav__link-wrapper {
		@include rem(padding-left, 16px);
	}

	.b-nav__item--lvl2 > .b-nav__link-wrapper {
		@include rem(padding-left, 43px);
	}

	.b-nav__item--lvl3 > .b-nav__link-wrapper {
		@include rem(padding-left, 63px);
	}
}
