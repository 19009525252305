$tooltips--bg-color: $color-jacksons-purple;
$tooltips--color: $color-white;

@if ($contrast) {
	$tooltips--bg-color: #484848;
	$tooltips--color: $color-white;
}

.b-tooltip {
	display: inline;
	@include rem(border-bottom-width, 1px);
	border-bottom-style: dashed;
	transition: border-bottom 0.2s ease, color 0.2s ease;

	&:hover,
	&.is-hover {
		// color: $color-zambezi;
		// border-bottom-color: transparent;
		cursor: help;
	}

	.b-tooltip__text {
		display: none;
	}
}

.b-tooltips-footnotes,
.b-footnotes,
.b-tooltip-number {
	display: none;
}

.tooltipster-sidetip.tooltipster-tooltips {
	.tooltipster-box {
		border: none;
		background: $tooltips--bg-color;
		color: $tooltips--color;
		@include font(300, 'Roboto');
		font-size: 12px;
		line-height: 15px;
		border-radius: 0;
	}

	.tooltipster-content {
		color: $tooltips--color;
		padding: 10px 10px 10px 12px;
		line-break: 15px;

		a,
		i {
			color: $tooltips--color;
		}
	}

	.tooltipster-arrow {
		height: 11px;
		margin-left: -8px;
		width:16px;
	}

	&.tooltipster-left .tooltipster-arrow,
	&.tooltipster-right .tooltipster-arrow {
		height: 16px;
		margin-left: 0;
		margin-top: -8px;
		width: 11px;
	}

	.tooltipster-arrow-background {
		border: 7px solid transparent;
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: $tooltips--bg-color;
		top: 0;
		border-bottom-width: 11px;
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: $tooltips--bg-color;
		left: 0;
		border-left-width: 11px;
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: $tooltips--bg-color;
		left: 0;
		border-right-width: 11px;
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: $tooltips--bg-color;
		top: 0;
		border-top-width: 11px;
	}

	.tooltipster-arrow-border {
		border: none;
	}

	// &.tooltipster-bottom .tooltipster-arrow-uncropped {
	// 	top: -8px;
	// }

	// &.tooltipster-right .tooltipster-arrow-uncropped {
	// 	left: -8px;
	// }

	&.tooltipster-bottom .tooltipster-arrow {
		top: -2px;
	}
	&.tooltipster-top.tooltipster-arrow {
		bottom: -2px;
	}
	&.tooltipster-left .tooltipster-arrow {
		right: -2px;
	}
	&.tooltipster-right .tooltipster-arrow {
		left: -2px;
	}

	&.tooltipster-bottom .tooltipster-arrow-uncropped {
		top: -8px;
	}
	&.tooltipster-top .tooltipster-arrow-uncropped {
		bottom: -1px;
	}
	&.tooltipster-left .tooltipster-arrow-uncropped {
		right: -1px;
	}
	&.tooltipster-right .tooltipster-arrow-uncropped {
		left: -8px;
	}
}
