.inset {
	position: relative;
	color: $color-davys-grey;
	font-size: 14px;
	font-weight: 400;
	border-left: 2px solid currentColor;
	@include rem(padding-left, 15px);
}

.inset--with-title {
	@include rem(padding, 25px 25px 25px);
	border: 1px solid $color-davys-grey;
	color: $color-davys-grey;

	figcaption {
		font-size: 18px;
		font-weight: bold;
		color: $color-davys-grey;
		position: absolute;
		top: 0;
		@include rem(left, 15px);
		transform: translateY(-50%);
		background: #fff;
		@include rem(padding, 0 10px);

		@include breakpoint(xs) {
			font-size: 14px;
			position: relative;
			top: 0;
			left: -10px;
			margin-top: -34px;
			transform: none;
			padding: 0 10px;
		}
	}

	& > *:last-child {
		margin-bottom: 0;
	}
}

.inset--with-frame {
	padding: 45px;
	border-left: none;
	border: solid 3pt $color-golden-ray;
	margin-bottom: 1em;
	@include font(300, 'Roboto');
	font-size: 1.2em;
	line-height: 1.3em;
	margin-bottom: 17px;
	text-transform: uppercase;
	color: #005698;

	ul li {
		font-size: 1em !important;
		line-height: 1.08em !important;
		color: #005698 !important;
	}

	@include breakpoint(sm) {
		font-size: 16px;
		line-height: 18px;
		padding: 15px;
	}
}