.content-area,
#colorbox,
.tooltipster-base {
	li {
		position: relative;
		text-align: left;
		margin-top: 0;

		@include font(300, 'Roboto');
		font-size: 16px;
		line-height: 19px;

		color: $color-black;
	}

	ul {
		margin-bottom: 42px;

		& > li {
			padding-left: 16px;
			margin-bottom: 13px;
			position: relative;
			@include webfont-icon($webfont-icon--i-list);

			&:before {
				position: absolute;
				color: #005698;
				top: 5px;
				border-radius: 100%;
				left: 1px;
				width: 10px;
				height: 10px;
				font-size: 10px;
			}

			li {
				padding-left: 32px;

				&:before {
					content: "";
					top: 7px;
    				left: 18px;
					width: 6px;
					height: 6px;
					font-size: 6px;
					background-color: #005698;
				}

				li {
					&:before {
						top: 7px;
    					left: 21px;
						width: 4px;
						height: 4px;
						font-size: 4px;
					}
				}
			}
		}
	}

	ul.current-color {
		& > li:before {
			background-color: currentColor;
			border-color: currentColor;
		}
	}

	ol.current-color {
		& > li:before {
			color: currentColor;
		}
	}

	//Список без маркеров
	.no-markers {
		li {
			padding-left: 0;

			&:before {
				display: none;
			}
		}
	}

	//Нумерованный список
	ol {
		counter-reset: list-numbers;
		margin-bottom: 49px;

		& > li {
			padding-left: 19px;
			margin-bottom: 13px;

			&:before {
				content: counters(list-numbers, ".");
				counter-increment: list-numbers;
				display: block;
				width: 20px;
				position: absolute;
				top: 0;
				left: 0;

				@include font(700, 'FiraSansExtraCondensed');
				font-size: 18px;

				color: $color-jacksons-purple;

			}

			li {
				padding-left: 37px;
				&:before {
					font-size: 16px;
					left: 12px;
				}

				li {
					padding-left: 48px;
					&:before {
						left: 15px;
					}
				}
			}
		}
	}

	ul ol,
	ol ul,
	ul ul,
	ol ol {
		margin: 13px 0 13px;
	}
}

.b-table {
	ul {
		& > li {
			font-size: 15px;
			&:before {
				top: calc(0.3em * 1.2);
			}
		}

		ul li {
			&:before {
				top: calc(0.35em * 1.2);
			}

			ul li {
				&:before {
					top: calc(0.375em * 1.2);
				}
			}
		}
	}
}

@mixin reset-counter($counterName) {
	ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ul li ol {
		counter-reset: $counterName;

		li {
			&:before {
				content: counters($counterName, ".");
				counter-increment: $counterName;
			}
		}
	}

	ol li ul li {
		&:before {
			text-indent: -9999px;
		}
	}
}

.b-accordion {
	@include reset-counter(list-accordion);
}

.b-table {
	@include reset-counter(list-table);
}

.b-tabs {
	@include reset-counter(list-tabs);
}
