

.body--slider-single {
	.l-layout-wrapper__header {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.l-page {
		padding: 0;
		left: 0;
		&__content {
			width: 100%;
		}
	}

	.l-header-top-nav,
	.l-layout-wrapper--1 .l-footer{
		background-color: #555555;
	}

	.b-nav--top.b-nav--horizontal-cols .b-nav__link--lvl1 {
		color: #F5F5F5;
		opacity: .9;

		&:hover {
			color: #FFCD00;
			border-bottom-color: rgba(255, 205, 0, .5);
		}
	}

	.b-copyright,
	.b-copyright__name,
	.b-social .b-social__link:before, .b-social .b-social__button:before,
	.b-custom-links__link {
		color: #F5F5F5;
	}

	.l-layout-wrapper--1 .content-area {
		padding: 0;
	}

	.l-layout-wrapper__page {
		max-width: 100%;
		width: 100%;
		flex-basis: auto;
	}

	.b-mainpage {
		background-color: #141718;
		position: relative;
		min-height: 763px;//578px;
		height: calc(100vh - 146px - 6px);

		&__image-wrap {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		&__image {
			max-width: none;
			width: 1920px !important;//100%;
			height: 1132px !important;//759px;//100%;
			object-fit: cover;
			position: absolute;
			top: 0 !important;
			transition: transform 1s ease;
			margin-top: 0 !important;

			&--left {
				left: 50% !important;
				transform: translateX(-100%);
				margin-left: 0 !important;
				background-image: url(/images/display/main/bg1.png);
				background-size: cover;
				background-position: center;
				&.anim {
					transform: translateX(-50%);
				}
			}

			&--right {
				right: 50% !important;
				left: auto !important;
				transform: translateX(100%);
				background-image: url(/images/display/main/bg2.png);
				background-size: cover;
				background-position: center;
				&.anim {
					transform: translateX(50%);
				}
			}

			// &.anim {
			// 	transform: translateX(0);
			// }
		}

		&__image-bg {
			position: absolute;
			top: 0 !important;
			left: 50% !important;
			margin: 0 !important;
			transform: translateX(-50%);
			max-width: none;
			width: 1920px !important;//100%;
			height: 1132px !important;//759px;//100%;
			opacity: 0;
			object-fit: cover;
			transition: opacity .3s ease;
			background-image: url(/images/display/main/bg.png);
			background-size: cover;
			background-position: center;

			&.anim {
				opacity: .9;
			}
		}

		&__navigation {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			opacity: 0;
			transition: opacity .3s ease;

			&.anim {
				opacity: .9;
			}
		}

		&__list {
			position: relative;
			width: 100%;
			height: 100%;
			// max-width: 960px;
			border-radius: 100%;
			margin: auto;
			// height: 57vh;
		}

		li.b-mainpage__item {
			position: absolute;

			&::before {
				display: none;
			}

			&--1 {
				bottom: 329px;
				left: 50%;
				margin-left: -467px;

				& .b-mainpage__link {
					max-width: 179px;
					text-align: right;
					padding-right: 28px;
					&::before {
						left: auto;
						top: auto;
						right: 0;
						bottom: 0;
					}

					&::after {
						left: auto;
						right: 0;
					}

					&:hover {
						&::before {
							height: 40px;
							width: 40px;
							bottom: -10px;
							right: -10px;
						}
						
					}
				}
			}

			&--2 {
				top: 128px;
				left: 50%;
				margin-left: -503px;

				.browser-opera-26 & {
					margin-left: -527px;
				}

				& .b-mainpage__link {
					text-align: right;

					&::before {
						left: auto;
						top: auto;
						right: -20px;
						bottom: -6px;
					}

					&::after {
						left: auto;
						bottom: 3px;
						right: -17px;
					}
					&:hover {
						&::before {
							height: 40px;
							width: 40px;
							bottom: -12px;
							right: -32px;
						}
						
					}
				}
			}

			&--3 {
				top: 72px;
				left: 50%;
				margin-left: -140px;

				& .b-mainpage__link {
					text-align: right;
					max-width: 128px;

					&::before {
						left: auto;
						top: auto;
						bottom: 0;
						right: -32px;
					}

					&::after {
						left: auto;
						bottom: 5px;
						right: -17px;
					}

					&:hover {
						&::before {
							bottom: -10px;
							right: -40px;
						}
					}

					[lang="en"] & {

					}
				}
				
			}

			&--4 {
				right: 50%;
				top: 317px;
				margin-right: -314px;

				& .b-mainpage__link {
					max-width: 66px;

					&::before {
						top: -25px;
						left: -35px;
					}

					&::after {
						left: -25px;
						top: -21px;
						bottom: auto;
					}

					&:hover {
						&::before {
							top: -35px;
							left: -44px;
						}
					}
				}
			}

			&--5 {
				bottom: 111px;//100px;
				right: 50%;
				margin-right: -316px;

				& .b-mainpage__link {
					max-width: 120px;
					padding-left: 25px;

					&::before {
						top: -29px;
						left: -15px;
					}

					&::after {
						top: -24px;
					}

					&:hover {
						&::before {
							top: -38px;
							left: -24px;
						}
					}
				}
			}

			&--6 {
				bottom: 29px;//25px;
				left: 50%;
				margin-left: -9px;

				& .b-mainpage__link {
					max-width: 180px;
					padding-bottom: 0;
					padding-top: 25px;

					&::before {
						left: -45px;
					}

					&::after {
    					top: 5px;
    					left: -35px;
					}

					&:hover {
						&::before {
							top: -10px;
							left: -55px;
						}
					}
				}
			}
		}
		

		&__link {
			font-size: 26px;
			line-height: 26px;
			color: #FFF5CC;
			@include font(400, 'FiraSansExtraCondensed');
			text-transform: uppercase;
			position: relative;
			text-decoration: none;
			max-width: 279px;
			display: inline-block;
			padding-bottom: 25px;

			.browser-ie-11 & {
				max-width: 284px;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(255, 245, 204, 0.5);
				border: 2px solid #FFCD00;
				height: 22px;
				width: 22px;
				border-radius: 100%;
			}

			&:hover,
			&:active {
				color: #FFCD00;
				@include webfont-icon($webfont-icon--i-arrow-main, after);

				&::before {
					@include svg(100%, 100%, "/images/icons/i-main-circle");
					background-color:transparent;
					border: none;
					height: 40px;
					width: 40px;
					bottom: -10px;
					left: -10px;
					z-index: 2;
				}

				&::after {
					position: absolute;
					bottom: 5px;
					left: 0;
					color: #FFCD00;
					font-size: 10px;
					z-index: 1;
				}
			}
		}
	}
	@media (min-height: 900px) {
		.b-mainpage {
			li.b-mainpage__item {
				&--5 {
					bottom: 124px;//140px;
				}
				&--6 {
					bottom: 43px;//60px;
				}
			}
		}
	}
	@media (min-height: 950px) {
		.b-mainpage {
			&__image-bg,
			&__image {
				height: 1080px !important;
			}
			&__navigation {
				height: 733px;
			}

			li.b-mainpage__item {
				&--2 {
					top: 104px;
				}

				&--3 {
					top: 46px;
				}

				&--4 {
					margin-right: -322px;
				}

				&--5 {
					bottom: 95px;
					margin-right: -308px;
				}

				&--6 {
					bottom: 26px;
				}
			}
		}
	}
	@media (max-width: 1440px) {
		.l-layout-wrapper--1 .l-page {
			padding-left: 0;
		}

		.b-mainpage {
			min-height: 578px;
			height: calc(100vh - 129px - 23px);
			&__image-bg,
			&__image {
				width: 1440px !important;
				height: 866px !important;//578px;
			}

			&__navigation {
				height: 585px;
			}

			li.b-mainpage__item {
				&--1 {
					bottom: 219px;
					margin-left: -394px;
				}

				&--2 {
					top: 89px;
					margin-left: -453px;
				}

				&--3 {
					top: 48px;
					margin-left: -143px;
				}

				&--4 {
					top: 257px;
					margin-right: -259px;
				}

				&--5 {
					bottom: 45px;
					margin-right: -260px;
				}

				&--6 {
					bottom: 6px;
				}
			}
		}
	}

	@include breakpoint(md) {
		.b-mainpage {
			height: calc(100vh - 55px - 52px);

			// &__image-bg,
			// &__image {
			// 	width: 1024px;
			// 	height: calc(100vh - 55px - 52px);
			// }

			&__navigation {
				height: 626px;
			}

			li.b-mainpage__item {
				&--1 {
					bottom: 255px;
					margin-left: -392px;
				}

				&--2 {
					top: 89px;
					margin-left: -453px;
				}

				&--3 {
					top: 47px;
				}

				&--4 {
					top: 243px;
					margin-right: -254px;
				}

				&--5 {
					bottom: 104px;
					margin-right: -272px;
				}

				&--6 {
					bottom: 47px;
					margin-left: 10px;
				}
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 .l-page {
			padding-bottom: 0;
		}

		.b-mainpage {
			height: calc(100vh - 55px - 57px);
			display: flex;
			flex-direction: column;
			justify-content: center;
			min-height: 700px;

			&__image-bg,
			&__image {
				width: 1024px !important;
				height: 539px !important;
			}

			&__image-bg {
				transform: translateX(calc(-49% + 2px));
			}

			&__image {
				&--left {
					&.anim {
						transform: translateX(calc(-49% + 2px));
					}
				}
				&--right {
					&.anim {
						transform: translateX(51%);
					}
				}
			}

			li.b-mainpage__item {
				position: static;
				margin: 0 0 4px;
				padding: 0;

				&--1,
				&--2,
				&--3,
				&--4,
				&--5,
				&--6 {
					.b-mainpage__link {
						max-width: none;
						padding: 0;
						text-align: left;

						&::before {
							left: 0;
							top: 0;
						}
					}
				}
			}

			&__link {
				font-size: 18px;
				display: flex;
				align-items: center;

				&::before {
					margin-right: 10px;
					display: inline-block;
					vertical-align: middle;
					position: static;
					width: 8px;
					height: 8px;
					border-width: 2px;
				}

				&:hover {
					&::before {
						margin-right: 10px !important;
						display: inline-block;
						vertical-align: middle;
						position: static;
						width: 8px !important;
						height: 8px !important;
						border-width: 2px !important;
					}	
				}

				&::after {
					display: none !important;
				}
			}

			&__navigation {
				height: auto;
				padding: 390px 17px 0;
			}
		}
	}

	@include breakpoint(xs) {
		&__link {
			font-size: 12px;
		}
	}
}
