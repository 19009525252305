.b-useful-links--compact {
	margin: 0;
	padding: 0;
	.b-useful-links {

		&__button {
			width: 100%;
			@include font(600, 'FiraSansExtraCondensed');
			font-size: 16px;
			line-height: 21px;
			background: $color-whisper;
			position: relative;
			text-align: left;
			color: #000000;
			padding: 7px 15px 4px;

			@include webfont-icon($webfont-icon--i-arrow-down);

			&:before {
				position: absolute;
				display: block;
				@include rem(width, 24px);
				height: 9px;
				font-size: 9px;
			
				color: #626262;
				top: 0;
				bottom: 0;
				margin: auto;
				right: 16px;
				transition: transform 0.2s ease;
			}

			&:hover,
			&:active {
				color: #302884;
				background: $color-drover;
				&:before {
					color: #302884;
				}
			}

			&.is-active {
				color: #302884;
				background: $color-sunglow;

				&:before {
					color: #302884;
					transform: rotate(180deg);
				}
			}
		}

		&__list {
			display: none;
			padding: 13px 10px 10px 17px;
		}

		&__item {
			position: relative;
			padding-left: 16px;
			margin-bottom: 3px;

			&:before {
				content: "";
				width: 6px;
				height: 6px;
				border-radius: 100%;
				background: #302884;
				position: absolute;
				top: 12px;
				left: 0;
			}
		}

		&__link {
			@include font(400, 'Roboto');
			
			font-size: 14px;
			line-height: 16px;
			text-decoration: none;

			color: #302884;

			&:hover,
			&:focus {
				text-decoration: underline;
				color: #5F5E5E;
			}

			&:active {
				text-decoration: underline;
				color: #FFCD00;
			}
		}
	}
}
