$custom-links--color: #5F5E5E;
$custom-links--color-hover: #302884;
$custom-links--color-active: #FFCD00;

$custom-links--tooltip-bg: $color-taupe-gray;
$custom-links--tooltip-color: $color-white;

$custom-links--menu-color: $color-white;
$custom-links--menu-color-hover: $color-mountain-mist;
$custom-links--menu-color-active: $color-tapa-gray;
$custom-links--menu-bg: $color-tundora;


.b-custom-links {
	font-size: 16px;
	line-height: 21px;
	@include font(300, "Roboto");

	.b-tooltip {
		border-bottom: none;
	}

	&__link {
		position: relative;
		color: $custom-links--color;
		text-decoration: underline;
		@include font(300, "Roboto");

		&:visited {
			color: $custom-links--color;
			@include font(300, "Roboto");
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: $custom-links--color-hover;
			@include font(300, "Roboto");
		}

		&:active,
		&:visited:active {
			color: $custom-links--color-active;
			@include font(300, "Roboto");
		}
	}

	.body--main-page &__link {
		color: #F5F5F5;

		&:visited {
			color: #F5F5F5;
		}

		&:hover,
		&:focus,
		&:visited:hover {
			color: #FFCD00;
		}

		&:active {
			color: #FFCD00;
		}
	}

	&__link--static {
		color: $custom-links--color;
		text-decoration: none;
	}

	@include breakpoint(sm) {
		display: none;
		font-size: 13px;
		line-height: 17px;
		&__list {
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
		}

		&__item {
			padding: 0 !important;
		}
	}

	@include breakpoint(xs) {
		&__list {
			flex-direction: column;
			justify-content: center;
		}

		&__item {
			text-align: center;
		}
	}
}

.b-custom-links--icons {
	.b-custom-links__link-icon {
		position: relative;
		display: none;
	}
}

.b-custom-links--columns {
	.b-custom-links__list {
		display: inline-block;
		vertical-align: top;
	}
}

.b-custom-links--inline {
	.b-custom-links__item {
		display: inline-block;
		@include rem(padding-left, 15px);
	}
}

.b-custom-links--footer {
	.b-custom-links__link-icon {
		@include rem(top, 3px);

		&:before {
			@include font-size(18px);
		}
	}
}

// Стили для ссылок в варианте меню menu-content
.b-custom-links--menu-horizontal-content {
	width: 100%;
	background-color: $custom-links--menu-bg;
	@include rem(padding, 5px 5px 5px 10px);

	.b-custom-links__link {
		position: relative;
		color: $custom-links--menu-color;
		display: inline-block;
		vertical-align: middle;
		text-decoration: none;
		@include rem(padding-left, 30px);
		text-decoration: underline;

		&:focus {
			color: $custom-links--menu-color;
		}

		&:hover,
		&:visited:hover {
			color: $custom-links--menu-color-hover;
		}

		&:active,
		&:visited:active {
			color: $custom-links--menu-color-active;
		}

		.b-custom-links__link-text {
			display: inline-block;
			text-decoration: underline;
		}

		.b-custom-links__link-icon {
			position: absolute;
			display: block;
			left: 0;

			&:before {
				@include font-size(18px);
			}
		}
	}

	.b-custom-links__link--static {
		.b-custom-links__link-text {
			color: $custom-links--color;
			text-decoration: none;
		}
	}

	.b-custom-links__link--questions-answers {
		.b-custom-links__link-icon {
			@include rem(top, 6px);
			@include webfont-icon($webfont-icon--clipboard);
		}
	}

	.b-custom-links__link--events {
		.b-custom-links__link-icon {
			top: 0;
			@include webfont-icon($webfont-icon--file-excel);
		}
	}

	.b-custom-links__link--sitemap {
		.b-custom-links__link-icon {
			@include rem(top, -1px);
			@include webfont-icon($webfont-icon--tree);
		}
	}
}

@if ($responsive) {
	@include breakpoint(md) {
		.b-custom-links {
			.b-custom-links__item {
				display: block;
			}
		}

		.b-custom-links--footer {
			.b-custom-links__link {
				line-height: 1;
			}
		}

		.b-custom-links--menu-horizontal-content {
			.b-custom-links__item {
				@include rem(padding-top, 5px);
			}
		}
	}
}
