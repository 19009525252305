@keyframes blink {
	0%   { opacity: 1; }
	10%  { opacity: 0; }
	25%  { opacity: 1; }
	100% { opacity: 1; }
};

.content-parse-attention{
	display: none;
	$bg-color: #ff3747;
	$border-color: darken( $bg-color, 40% );
	$text-color: #fff;
	opacity: 1;
	@include rem('padding', 20px);
	@include rem('margin', 20px 0);
	@include rem('border-radius', 15px);
	@include font-size(18px);
	@include animation(blink 10s infinite linear);
	animation-fill-mode: forwards;
	animation-delay: 2s;
	background: $bg-color;
	border: 1px solid $border-color;
	color: $text-color;

	&:before{
		content: attr(data-msg);
		@include font-size(20px);
		font-weight: bold;
		display: block;
		@include rem('margin-bottom', 5px);
	}

	&:hover{
		@include animation(none);
	}
}

.content-parse-attention--disable-blink {
	animation: none;
}

.content-parse-attention--compact {
	display: none;
	$bg-color: #ff3747;
	$border-color: darken( $bg-color, 40% );
	@include rem('padding', 5px);
	@include rem('width', 35px);
	@include rem('height', 35px);
	@include rem('margin', 5px 0);
	border-radius: 5px;
	position: relative;
	overflow: visible;
	text-align: center;

	&:before {
		content: '';
		display: none;
	}

	& > span {
		position: absolute;
		left: 0;
		bottom: 100%;
		z-index: 999;
		color: #000;
		min-width: 300px;
		display: none;
		@include rem('padding', 5px);
		border: 1px solid $border-color;
		border-radius: 5px;
		text-align: left;
		transition: opacity 0.2s linear;
		line-height: 1.3;
		background: #fff;
		margin: 0;
	}

	&:hover>span {
		display: block;
	}

	&:after {
		content: '!';
		color: #fff;
		@include font-size(20px);
		font-weight: bold;
	}
}
