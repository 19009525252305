.l-layout-wrapper--1 {
	.browser-ie & {
		height: 100%;
	}

	.l-layout-wrapper__header.is-fixed {
		position: static;
		top: 0;
		z-index: 10;
		width: $site-width;
	}

	.l-header-top-line {
		padding: 13px 0 8px;
		background-color: $top-line-bg;

		&__wrapper {
			display: -webkit-flex;
			display: flex;
			align-items: center;
			max-width: 1480px;
			width: 100%;
			margin: 0 auto;
			padding-left: calc(3vw - 1px);//calc(6vw - 1px);
			width: 100%;

			.browser-safari-6 & {
				display: -webkit-box;
				display: -ms-flexbox;
				display: -webkit-flex; /* Safari 6.1+ */
				display: flex;
				-webkit-flex-wrap: wrap; /* Safari 6.1+ */
				-ms-flex-wrap: wrap;
					flex-wrap: wrap;
				-webkit-box-pack: justify;
					-ms-flex-pack: justify;
					-webkit-justify-content: space-between; /* Safari 6.1+ */
						justify-content: space-between;
				.l-header-top-line__logo,
				.l-header-top-line__tools,
				.l-header-top-line__title {
					width: 31.33%;
					display: inline-block;
					vertical-align: middle;
				}

				.l-header-top-line__tools {
					width: 20%;
					text-align: right;
				}
			}
		}

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__title {
			flex-shrink: 0;
			flex-grow: 1;
			text-align: center;
			padding-right: calc(10vw + 13px);//calc(5vw + 6px);
			margin-top: -3px;
			// flex-basis: 300px;
			// width: 300px;
		}

		&__tools {
			display: flex;
			justify-content: flex-end;
			padding-right: 13px;
			margin-top: -5px;
		}
	}

	.l-header-top-nav {
		display: flex;
		border-bottom: 1px solid #9D9D9C;
		position: relative;
		padding-top: 9px;

		&__logo {
			flex-shrink: 0;
			margin-right: 12px;
		}

		&__menu {
			flex-grow: 1;
			flex-shrink: 1;
			max-width: 1455px;
			margin: 0 auto;
			padding-left: calc(0vw + 7px);//calc(3vw + 7px);
			width: 100%;
		}
	}

	.l-page {
		&__nav-breadcrumbs {
			width: 100%;
			max-width: calc(100% - 150px); // минус ширина page-nav'a
		}
	}

	.body--sitemap & {
		.l-layout-wrapper__page {
			max-width: 976px;
			padding: 13px 16px 0;
			margin: 0 auto;
		}

		.l-page__title h1 {
			margin-bottom: 25px;
			border: none;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
		}
	}

	.l-buttons-area {
		display: flex;
		align-items: center;

		&__item + .l-buttons-area__item {
			margin-left: 22px;
		}

		&__search {
			margin-left: 41px;
			.search {
				width: auto;
				display: block;
			}
		}

		&__mobile-nav-panel {
			display: none;
		}

		&__link {
			@include font(400, 'Roboto');
			font-size: 14px;
			line-height: 16px;
			color: #FFFFFF;
			text-decoration: none;
			&:hover,
			&:focus {
				color: #FFEB99;
				text-decoration: underline;
			}
			&:active {
				color: #FFCD00;
				text-decoration: underline;
			}
		}
	}

	.l-footer {
		display: flex;
		@include rem(padding-top, 5px);
		@include rem(padding-bottom, 5px);
		background-color: $color-white-smoke;

		&__inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			max-width: 1219px;
			margin: 0 auto;
			padding-right: calc(1vw - 10px);
		}

		&__nav {
			text-align: right;
		}
	}
}

@if ($responsive) {
	.l-layout-wrapper--1 {
		.l-layout-wrapper__header.is-fixed {
			width: 100%;
			max-width: $site-width;
			min-width: $site-min-width;
		}
	}

	@media (max-width: 1440px) {
		.l-layout-wrapper--1 {
			.l-header-top-line {

				&__wrapper {
					padding-left: 26px;
					padding-right: 16px;
				}
			}

			.l-header-top-nav__menu {
				padding-left: 16px;
				padding-right: 16px;
			}

			.l-page {
				padding-left: 16px;
				left: 0;

				.title--search & {
					padding-right: 16px;
				}
			}
		}
	}

	@media (max-width: 1199px) {
		.l-layout-wrapper--1 {
			.l-buttons-area {
				&__mobile-nav-panel {
					display: block;
				}
			}

			.l-header-top-nav {
				display: none;
			}
		}
	}

	@include breakpoint(md) {
		.l-layout-wrapper--1 {
			.l-header-top-line__logo {
				display: block;
			}

			.l-header-top-nav__logo {
				display: none;
			}

		}
	}

	@media (max-width: 1000px) {
		.l-layout-wrapper--1 {
			.l-header-top-line__title {
				padding-right: 10px;
			}

			.l-buttons-area {
				&__search {
					margin-left: 5px;
				}

				&__item + &__item {
					margin-left: 9px;
				}
			}
		}
	}

	@include breakpoint(sm) {
		.l-layout-wrapper--1 {
			.l-footer__copyright {
				@include rem(margin-bottom, 10px);
			}

			.l-page {
				flex-direction: column;
				padding-bottom: 50px;
			}

			.l-page__sidebar {
				flex-basis: auto;
				width: auto;
			}

			.l-page__nav-breadcrumbs {
				width: 100%;
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-header-top-line {
				padding: 11px 4px 11px 13px;

				&__logo {
					display: flex;
					margin-right: 22px;
				}

				&__title {
					padding: 0;
				}

				&__wrapper {
					padding: 0;
				}

				&__tools {
					margin: 0;
				}
			}

			.l-header-top-nav {
				display: none;
			}

			.l-buttons-area {
				&__link-wrap,
				&__search,
				&__lang,
				&__buttons-panel,
				&__pdf {
					display: none;
				}

				&__mobile-nav-panel {
					display: block;
				}
			}

			.l-footer {
				padding-left: 11px;
				padding-bottom: 14px;
				&__inner {
					padding: 0;
					flex-wrap: wrap;
				}

				&__copyright {
					margin: 0;
				}

				&__nav {
					flex: 1 0 100%;
					margin-top: 8px;
				}
			}
		}
	}

	@include breakpoint(xs) {
		.l-layout-wrapper--1 {
			.l-page__nav {
				flex-direction: column;
				align-items: flex-start;
			}

			.l-page__nav-breadcrumbs {
				max-width: 100%;
				@include rem(margin-bottom, 10px);
			}

			.l-page__nav-controls {
				flex-basis: auto;
				width: auto;
			}

			.l-footer {
				&__inner {
					justify-content: center;
				}
			}
		}
	}
}
