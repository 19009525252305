$noty-bg: $color-light-gray;
$noty-color: $base-text-color;
$noty-close-icon-color: $color-star-dust;
$noty-close-icon-color-hover: $color-black;
$noty-close-icon-color-active: $color-carrot-orange;

@if ($contrast) {
	$noty-bg: $color-mine-shaft;
	$noty-color: $color-white;
	$noty-close-icon-color: $color-mine-shaft;
	$noty-close-icon-color-hover: $color-white;
	$noty-close-icon-color-active: $color-white;
}

#noty_layout__default {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99999;
}

#noty_layout__default.noty_layout--static {
	position: relative;
}

.noty_bar {
	width: 100%;
}

.b-noty {
	background-color: $noty-bg;
	margin: 0 auto;
	width: 100%;
	position: relative;

	&__body {
		padding-top: 20px;
		padding-bottom: 20px;
		margin: auto;
		max-width: $site-width;
		padding-left: 19px;
		padding-right: 19px;

		#cookie-law & {
			padding-top: 30px;
			padding-bottom: 0;
		}
	}

	&__body--narrow {
		max-width: 1119px;
		background-color: $color-white;
		padding-left: 30px;
		padding-right: 30px;

		&#cookie-law {
			border: 1px solid $color-star-dust;
			padding-bottom: 17px;
		}
		&#old-browser{
			border-bottom: 1px solid $color-star-dust;
			border-left: 1px solid $color-star-dust;
			border-right: 1px solid $color-star-dust;
			margin-top: -15px;
			z-index: 10;
		}
	}

	&__title {
		display: block;
		@include rem(margin-bottom, 17px);
		@include font(600, 'FiraSansExtraCondensed');
		font-size: 22px;
		line-height: 28px;
		text-transform: uppercase;
		color: $color-jacksons-purple;
	}

	&__message,
	&__text {
		color: $noty-color;
		display: block;
	}

	&__close {
		position: absolute;
		bottom: 15px;
		right: 30px;
		padding: 0;
		background-color: transparent;
		color: $noty-close-icon-color;
		@include webfont-icon($webfont-icon--close_2);
		@extend %webfont-button-animation; /* /src/styles/styles/utilities.scss */

		&:before {
			@include font-size(20px);
		}

		&:hover,
		&:focus {
			color: $noty-close-icon-color-hover;
		}

		&:active {
			color: $noty-close-icon-color-active;
		}
	}
}

.b-noty--centered-content {
	.b-noty__message {
		text-align: center;
	}

	.b-noty__buttons {
		position: absolute;
		right: 20px;
		top: 50%;
		margin-top: -20px / 2;
	}
}

