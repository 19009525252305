.b-indicator {
	display: block;

	@include font(700,'FiraSansExtraCondensed');
	font-size: 16px;
	line-height: 21px;
	position: relative;
	padding-top: 60px;
	margin-bottom: 100px;//73px;
	max-width: 235px;
	width: 100%;
	color: $color-black;
	padding-bottom: 60px;

	&:before {
		content: "";
		// background: $color-sunglow;
		// width: 128px;
		// height: 128px;
		background: #FFD733;
		width: 296px;
		height: 296px;
		border-radius: 100%;
		position: absolute;
		left: -50px;
		top: 0;
		z-index: 1;
	}

	&__main {
		margin-bottom: 13px;
	}


	&__value {
		display: inline-block;
		@include font-size(54px);
		line-height: 1;
		z-index: 2;
		position: relative;
		margin-bottom: 0;
	}

	&__value--big {
		@include font-size(45px);
	}

	&__value--small {
		@include font-size(39px);
	}

	&__value--arrow {
		@include webfont-icon($webfont-icon--up);

		&:before {
			vertical-align: top;
			font-size: 0.5em;
		}
	}

	&__value--arrow-up {}

	&__value--arrow-down {
		&:before {
			transform: rotate(180deg);
		}
	}

	&__value--print {
		display: none !important;
	}

	&__unit {
		display: block;
		line-height: 1;
		z-index: 2;
		position: relative;
		font-size: 16px;
		@include font(600,'FiraSansExtraCondensed');
		font-weight: 500;
	}

	&__unit--big {
		@include font-size(30px);
	}

	&__unit--small {
		@include font-size(24px);
		font-weight: 400;
	}

	&__text {
		display: block;
		z-index: 2;
		position: relative;
		font-size: 16px;
		@include font(400,'FiraSansExtraCondensed');
	}

	&__text--big {
		@include font-size(18px);
		font-weight: 700;
	}

	&__text--small {
		@include font-size(12px);
	}

	&__value,
	&__unit,
	&__text {
		& + & {
			@extend %collapse--top;
		}
	}

	&__heading {
		position: relative;
		z-index: 2;
	}

	@include breakpoint(md) {
		padding-top: 30px;
		&::before {
			width: 240px;
			height: 240px;
		}
	}

	@include breakpoint(sm) {
		max-width: 220px;
		padding-left: 39px;

		&::before {
			left: 0;
		}

		&__main {
			margin-bottom: 1px;
		}

		&__text {
			font-size: 12px;
			line-height: 14px;
		}
	}
}
