$cookie-law--btn-color: $color-white;
$cookie-law--btn-color-hover: $color-white;
$cookie-law--btn-bg: $color-silver;
$cookie-law--btn-bg-hover: $color-tundora;
$cookie-law--btn-bg-active: $color-tundora;

@if ($contrast) {
	$cookie-law--btn-color: $color-ironside-gray;
	$cookie-law--btn-color-hover: $color-white;
	$cookie-law--btn-bg: $color-light-gray;
	$cookie-law--btn-bg-hover: $color-tundora;
	$cookie-law--btn-bg-active: $color-dark-jungle;
}

.b-noty--cookie-law {
	z-index: 10;

	.b-noty__buttons {
		display: block;
		@include rem(margin-top, 30px);
	}

	.b-cookie-law__btn {
		@include rem(width, 160px);
		@include rem(height, 40px);
		color: $cookie-law--btn-color;
		background-color: $cookie-law--btn-bg;
		padding: 0 20px;
		margin-bottom: 29px;

		&:not(:last-child) {
			margin-right: 37px;
		}

		&:hover {
			background-color: #FFEB99;
			color: #302884;
		}

		&:active {
			background-color: #FFD733;
			color: #302884;
		}
	}
}
